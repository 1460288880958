import {
  GET_GROUP_DETAILS_REQUEST,
  GET_GROUP_DETAILS_SUCCESS,
  GET_GROUP_DETAILS_FAILURE,
  SELECT_GROUP,
  RESET_SELECTED_GROUP,
  SELECT_PREVIOUS_INTERVAL,
  SELECT_NEXT_INTERVAL
} from 'actions/actionTypes';

const initialState = {
  stats: null,
  date: null,
  interval: null,
  loading: false,
  error: false,
  errorMessage: null,
  groupId: null
};

const selectedGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP_DETAILS_REQUEST: {
      return {
        ...state,
        error: false,
        loading: true
      };
    }
    case GET_GROUP_DETAILS_SUCCESS: {
      return {
        ...state,
        stats: action.payload.data,
        error: false,
        loading: false
      };
    }
    case GET_GROUP_DETAILS_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage
      };
    }
    case SELECT_GROUP: {
      return {
        ...state,
        groupId: action.payload.groupId,
        date: action.payload.date,
        interval: 'day'
      };
    }
    case RESET_SELECTED_GROUP: {
      return {
        initialState
      };
    }
    case SELECT_NEXT_INTERVAL: {
      return {
        ...state,
        date: action.payload.date
      };
    }
    case SELECT_PREVIOUS_INTERVAL: {
      return {
        ...state,
        date: action.payload.date
      };
    }
    default:
      return state;
  }
};

export default selectedGroupReducer;
