import _ from 'lodash';
import {
  GET_GROUPS_NAMES_FAILURE,
  GET_GROUPS_NAMES_REQUEST,
  GET_GROUPS_NAMES_SUCCESS,
  GET_TRACKER_REPORT_FAILURE,
  GET_TRACKER_REPORT_SUCCESS,
  GET_TRACKER_REPORT_REQUEST,
  CLEAR_TRACKER_REPORT,
  SET_GROUP_NAME,
  SET_PERIOD,
  GET_ORGANIZATIONS_FAILURE,
  GET_ORGANIZATIONS_SUCCESS,
  GET_ORGANIZATIONS_REQUEST,
  SELECT_ORGANIZATION,
  GET_ORGANIZATION_GROUPS_REQUEST,
  GET_ORGANIZATION_GROUPS_SUCCESS,
  GET_ORGANIZATION_GROUPS_FAILURE,
  SELECT_ORGANIZATION_GROUP,
  GET_ORGANIZATION_GROUP_PERIOD_SUCCESS,
  GET_ORGANIZATION_GROUP_PERIOD_REQUEST,
  GET_ORGANIZATION_GROUP_PERIOD_FAILURE,
  SELECT_ORGANIZATION_GROUP_PERIOD,
  GET_TRACKER_PERIOD_DETAILS_REQUEST,
  GET_TRACKER_PERIOD_DETAILS_SUCCESS,
  GET_TRACKER_PERIOD_DETAILS_FAILURE,
  CLEAR_TRACKER_PERIOD_DETAILS,
  GET_SUMMARY_STATISTIC_REQUEST,
  GET_SUMMARY_STATISTIC_SUCCESS,
  GET_SUMMARY_STATISTIC_FAILURE,
  SELECT_PREDEFINED_FIELDS
} from '../actions/actionTypes';

import HTTPService from '../utils/httpService';

const Moment = require('moment');
const momentTz = require('moment-timezone');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

export const getGroupsNames = () => dispatch => {
  dispatch({ type: GET_GROUPS_NAMES_REQUEST });

  const httpClient = new HTTPService().client;

  httpClient
    .get('tracker/report/groups')
    .then(response => {
      dispatch({
        type: GET_GROUPS_NAMES_SUCCESS,
        payload: {
          groupsNames: response.data
        }
      });
    })
    .catch(() => {
      dispatch({
        type: GET_GROUPS_NAMES_FAILURE,
        payload: {
          errorMessage: 'Could not get groups names'
        }
      });
    });
};

export const getTrackerReport = (groupName, fromDate, toDate) => dispatch => {
  dispatch({ type: GET_TRACKER_REPORT_REQUEST });

  const httpClient = new HTTPService().client;

  const today = new Date();
  const day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
  const month =
    today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1;
  const year = today.getFullYear();

  const from = fromDate || '2019-11-18';
  const to = toDate || `${year}-${month}-${day}`;

  return httpClient
    .get('tracker/report', {
      params: {
        from,
        to,
        groupName
      }
    })
    .then(response => {
      dispatch({
        type: GET_TRACKER_REPORT_SUCCESS,
        payload: {
          report: response.data,
          fromDate,
          toDate
        }
      });

      return response.data;
    })
    .catch(error => {
      console.error(error);

      dispatch({
        type: GET_TRACKER_REPORT_FAILURE,
        payload: {
          errorMessage: 'Could not get tracker report'
        }
      });
    });
};

export function clearReport() {
  return {
    type: CLEAR_TRACKER_REPORT
  };
}

export function setGroupName(groupName) {
  return {
    type: SET_GROUP_NAME,
    payload: {
      setGroupName: groupName
    }
  };
}

export function setPeriod(fromDate, toDate) {
  return {
    type: SET_PERIOD,
    payload: {
      period: fromDate ? { fromDate, toDate } : null
    }
  };
}

const roundTo60Min = ts => {
  const timezone = momentTz.tz.guess();
  const start = moment(ts).tz(timezone);
  const remainder = 60 - (start.minute() % 60);

  return moment(start)
    .add(remainder, 'minutes')
    .subtract(1, 'hour');
};

const STEP_FILTER = 1000;

const stepsPerHour = (blackListDateMap, data) => {
  const timezone = momentTz.tz.guess();
  return _(data)
    .filter(
      item =>
        !_(
          blackListDateMap[
            moment(item.timestamp)
              .tz(timezone)
              .format('YYYY-MM-DD')
          ]
        ).includes(item.deviceId)
    )
    .groupBy(d => roundTo60Min(d.timestamp))
    .mapValues((v, k) =>
      Math.round(
        _(v)
          .groupBy('deviceId')
          .map((v, k) => _(v).sumBy('steps'))
          .mean()
      )
    )
    .map((v, k) => {
      const m = moment(k).tz(timezone);
      const hour1 = m.format('HH');
      const hour2 = m.add(1, 'hours').format('HH');

      return {
        day: m.format('YYYY-MM-DD'),
        hour: hour1 + '-' + hour2,
        steps: v
      };
    })
    .groupBy('hour')
    .map((v, k) => ({
      hour: k,
      ..._(v)
        .groupBy('day')
        .mapValues((v, k) => _(v).sumBy('steps'))
        .value()
    }))
    .value();
};

const activityPerHour = (blackListDateMap, data) => {
  const timezone = momentTz.tz.guess();

  return _(data)
    .filter(
      item =>
        !_(
          blackListDateMap[
            moment(item.timestamp)
              .tz(timezone)
              .format('YYYY-MM-DD')
          ]
        ).includes(item.deviceId)
    )
    .groupBy(d => roundTo60Min(d.timestamp))
    .mapValues((v, k) =>
      Math.round(
        _(v)
          .groupBy('deviceId')
          .map(
            (v, k) =>
              _(v)
                .countBy(v => v.steps >= 65)
                .value().true
          )
          .mean()
      )
    )
    .map((v, k) => {
      const m = moment(k).tz(timezone);
      const hour1 = m.format('HH');
      const hour2 = m.add(1, 'hours').format('HH');

      return {
        day: m.format('YYYY-MM-DD'),
        hour: hour1 + '-' + hour2,
        steps: v
      };
    })
    .groupBy('hour')
    .map((v, k) => ({
      hour: k,
      ..._(v)
        .groupBy('day')
        .mapValues((v, k) => _(v).sumBy('steps'))
        .value()
    }))
    .value();
};

const cleanData = data => ({
  ...data,
  high: data.high,
  low: data.low,
  medium: data.medium,
  steps: data.steps,
  sed: data.sedentary,
  totalActMin: data.high + data.medium
});

const ignoredTrackersPerDateMap = aggregatedData =>
  _(aggregatedData)
    .map(cleanData)
    .filter(v => v.steps < STEP_FILTER)
    .groupBy('date')
    .mapValues(items => items.map(item => item.deviceId))
    .value();

const trackerReport = (from, to, d) => {
  const data = _.map(d, cleanData);

  let groupedByDevice = _(data)
    .filter(d => d.steps >= STEP_FILTER)
    .groupBy('deviceId')
    .mapValues((v, k) => ({
      trackerId: k,
      // avrLow: Math.round(_.meanBy(v, 'low')),
      avrMedHigh: Math.round(_.meanBy(v, o => o.medium + o.high)),
      avrSteps: Math.round(_.meanBy(v, 'steps')),
      dates: v,
      avrSedHoursMin: Math.round(_.meanBy(v, 'sedentary'))
    }))
    .value();

  return groupedByDevice;
};

const trackerReportWithoutStepsFilter = (from, to, d) => {
  const data = _.map(d, cleanData);

  let groupedByDevice = _(data)
    .groupBy('deviceId')
    .mapValues((v, k) => ({
      trackerId: k,
      // avrLow: Math.round(_.meanBy(v, 'low')),
      avrMedHigh: Math.round(_.meanBy(v, o => o.medium + o.high)),
      avrSteps: Math.round(_.meanBy(v, 'steps')),
      dates: v,
      avrSedHoursMin: Math.round(_.meanBy(v, 'sedentary'))
    }))
    .value();

  return groupedByDevice;
};

const stepsPerDay = (from, to, data, type = 'total') => {
  const timezone = momentTz.tz.guess();
  var stepsPerDay = _.map(data, d => ({
    steps: parseInt(d.steps),
    day: moment(d.date)
      .tz(timezone)
      .format('YYYY-MM-DD')
  }));

  stepsPerDay = _.filter(stepsPerDay, d => d.steps >= STEP_FILTER);

  stepsPerDay = _(stepsPerDay)
    .groupBy('day')
    .map((v, k) => ({
      day: k,
      steps:
        type === 'avg'
          ? Math.round(_.meanBy(v, 'steps'))
          : Math.round(_.sumBy(v, 'steps'))
    }))
    .value();

  stepsPerDay = _.zipObject(
    _.map(stepsPerDay, 'day'),
    _.map(stepsPerDay, v => v)
  );

  const periodRange = moment.range(from, to);
  const periodDays = Array.from(periodRange.by('days')).map(d =>
    d.tz(timezone).format('YYYY-MM-DD')
  );
  const periodDayMap = _.zipObject(
    periodDays,
    periodDays.map(d => ({ day: d, steps: 0 }))
  );

  const result = _.merge(periodDayMap, stepsPerDay);

  return result;
};

const activitySpread = (from, to, data) => {
  var activityPerDay = _.map(data, d => ({
    ...d,
    activity: d.medium + d.high,
    day: moment(d.date).format('YYYY-MM-DD'),
    steps: d.steps
  }));

  activityPerDay = _.filter(activityPerDay, d => d.steps >= STEP_FILTER);

  activityPerDay = _.groupBy(activityPerDay, 'deviceId');

  activityPerDay = _.map(activityPerDay, (v, k) => {
    const avgActivity = _(v)
      .map(v => v.activity)
      .mean();
    const intervalText = n => {
      switch (n) {
        case 1:
          return '0-15 min';
        case 2:
          return '15-30 min';
        case 3:
          return '30-45 min';
        case 4:
          return '45-60 min';
        default:
          return '60- min';
      }
    };
    return {
      trackerId: k,
      interval: intervalText(Math.trunc(avgActivity / 15) + 1)
    };
  });

  activityPerDay = _.groupBy(activityPerDay, 'interval');

  activityPerDay = _.map(activityPerDay, (v, k) => ({
    interval: k,
    count: _(v).size()
  }));

  activityPerDay = _.sortBy(activityPerDay, 'interval');

  return activityPerDay;
};

const stepSpread = (from, to, data) => {
  var stepsPerDay = _.map(data, d => ({
    ...d,
    day: moment(d.date).format('YYYY-MM-DD'),
    steps: d.steps
  }));

  stepsPerDay = _.filter(stepsPerDay, d => d.steps >= STEP_FILTER);

  stepsPerDay = _.groupBy(stepsPerDay, 'deviceId');

  stepsPerDay = _.map(stepsPerDay, (v, k) => {
    const avgSteps = _(v)
      .map(v => v.steps)
      .mean();

    const intervalText = n => {
      switch (n) {
        case 1:
          return '0-3000';
        case 2:
          return '3000-6000';
        case 3:
          return '6000-9000';
        case 4:
          return '9000-12000';
        default:
          return '12000-';
      }
    };

    return {
      trackerId: k,
      interval: intervalText(Math.trunc(avgSteps / 3000) + 1)
    };
  });

  stepsPerDay = _.groupBy(stepsPerDay, 'interval');

  stepsPerDay = _.map(stepsPerDay, (v, k) => ({
    sortKey: parseInt(k.split('-')[0]),
    interval: k,
    count: _(v).size()
  }));

  stepsPerDay = _.sortBy(stepsPerDay, 'sortKey');

  return stepsPerDay;
};

const avgActivityGenderDay = (blackListDateMap, data) => {
  const genders = _.map(_.uniqBy(data, 'gender'), 'gender');
  if (!_.includes(genders, 2) && !_.includes(genders, 3)) {
    return undefined;
  }

  const result = _(data)
    .groupBy(d => moment(d.timestamp).format('YYYY-MM-DD'))
    .value();

  const toGenderTxt = n => {
    switch (n) {
      case '1':
        return 'Neutral';
      case '2':
        return 'Flickor';
      case '3':
        return 'Pojkar';
      default:
        return 'Okänd';
    }
  };

  return _(result)
    .mapValues((v, k) =>
      _(v)
        .filter(item => !_(blackListDateMap[k]).includes(item.deviceId))
        .groupBy('deviceId')
        .map((v, k) => ({
          gender: v[0].gender,
          activity: _(v)
            .countBy(v => v.steps >= 65)
            .value().true
        }))
        .groupBy('gender')
        .map((v, k) => {
          return {
            [toGenderTxt(k)]: Math.round(_(v).meanBy('activity'))
          };
        })
        .value()
    )
    .mapValues((v, k) => {
      return _.merge.apply(_, v);
    })
    .map((v, k) => ({
      ...v,
      day: k
    }))
    .value();
};

const avgStepsGenderDay = (blackListDateMap, data) => {
  const genders = _.map(_.uniqBy(data, 'gender'), 'gender');
  if (!_.includes(genders, 2) && !_.includes(genders, 3)) {
    return undefined;
  }

  const result = _(data)
    .groupBy(d => moment(d.timestamp).format('YYYY-MM-DD'))
    .value();

  const toGenderTxt = n => {
    switch (n) {
      case '1':
        return 'Neutral';
      case '2':
        return 'Flickor';
      case '3':
        return 'Pojkar';
      default:
        return 'Okänd';
    }
  };

  return _(result)
    .mapValues((v, k) =>
      _(v)
        .filter(item => !_(blackListDateMap[k]).includes(item.deviceId))
        .groupBy('deviceId')
        .map((v, k) => ({
          gender: v[0].gender,
          steps: _(v).sumBy(v => v.steps)
        }))
        .groupBy('gender')
        .map((v, k) => {
          return {
            [toGenderTxt(k)]: Math.round(_(v).meanBy('steps'))
          };
        })
        .value()
    )
    .mapValues((v, k) => {
      return _.merge.apply(_, v);
    })
    .map((v, k) => ({
      ...v,
      day: k
    }))
    .value();
};

const avgLocationActivityDay = (blackListDateMap, data) => {
  const types = _.map(_.uniqBy(data, 'type'), 'type');

  const filteredData = _(data)
    .groupBy(d => moment(d.timestamp).format('YYYY-MM-DD'))
    .mapValues((dayList, key) => {
      const filteredDayList = _(dayList).filter(
        item => !_(blackListDateMap[key]).includes(item.deviceId)
      );
      return filteredDayList.value();
    })
    .value();

  const neededCount = Math.round(_.uniqBy(data, 'deviceId').length * 0.15);
  const haveEnoughFreeTimeTrackers = _(filteredData).some((dateList, index) => {
    const numberOfFreeTimeTrackers = _(dateList)
      .filter(item => item.type !== 0)
      .uniqBy(item => item.deviceId)
      .value().length;
    return numberOfFreeTimeTrackers > neededCount;
  });

  const toTypeTxt = n => {
    switch (n) {
      case '0':
        return 'Skoltid';
      default:
        return 'Fritid';
    }
  };

  const outputData = _(filteredData)
    .mapValues((v, k) => {
      const filtered = _(v).filter(
        item => !_(blackListDateMap[k]).includes(item.deviceId)
      );

      const devices = filtered.uniqBy('deviceId').size();
      return filtered
        .groupBy('type')
        .map((v, k) => ({
          type: k,
          activity: _(v)
            .countBy(v => v.steps >= 65)
            .value().true
        }))
        .groupBy('type')
        .map((v, k) => ({
          [toTypeTxt(k)]: Math.round(_(v).meanBy('activity') / devices)
        }))
        .value();
    })
    .mapValues((v, k) => {
      return _.merge.apply(_, v);
    })
    .map((v, k) => ({
      ...v,
      day: k
    }))
    .value();

  return {
    avgActivityPerDay: outputData,
    avgLocationActivityDay:
      types.length === 1 || !haveEnoughFreeTimeTrackers ? undefined : outputData
  };
};

const avgLocationStepsDay = (blackListDateMap, data) => {
  const filteredData = _(data)
    .groupBy(d => moment(d.timestamp).format('YYYY-MM-DD'))
    .mapValues((dayList, key) => {
      const filteredDayList = _(dayList).filter(
        item => !_(blackListDateMap[key]).includes(item.deviceId)
      );
      return filteredDayList.value();
    })
    .value();

  const toTypeTxt = n => {
    switch (n) {
      case '0':
        return 'Skoltid';
      default:
        return 'Fritid';
    }
  };

  return _(filteredData)
    .mapValues((v, k) => {
      const filtered = _(v).filter(
        item => !_(blackListDateMap[k]).includes(item.deviceId)
      );

      const devices = filtered.uniqBy('deviceId').size();
      return filtered
        .groupBy('type')
        .map((v, k) => ({
          type: k,
          steps: _(v).sumBy(v => v.steps)
        }))
        .groupBy('type')
        .map((v, k) => ({
          [toTypeTxt(k)]: Math.round(_(v).meanBy('steps') / devices)
        }))
        .value();
    })
    .mapValues((v, k) => {
      return _.merge.apply(_, v);
    })
    .map((v, k) => ({
      ...v,
      day: k
    }))
    .value();
};

const avgActivityPerDay = (from, to, data) => {
  var activityPerDay = _.map(data, d => ({
    medium: parseInt(d.medium),
    high: parseInt(d.high),
    day: moment(d.date).format('YYYY-MM-DD'),
    steps: parseInt(d.steps)
  }));

  activityPerDay = _.filter(activityPerDay, d => d.steps >= STEP_FILTER);

  activityPerDay = _(activityPerDay)
    .groupBy('day')
    .map((v, k) => ({
      day: k,
      activity: Math.round(
        _(v)
          .map(v => v.medium + v.high)
          .mean()
      )
    }))
    .value();

  activityPerDay = _.zipObject(
    _.map(activityPerDay, 'day'),
    _.map(activityPerDay, v => v)
  );

  const timezone = momentTz.tz.guess();
  const periodRange = moment.range(from, to);
  const periodDays = Array.from(periodRange.by('days')).map(d =>
    d.tz(timezone).format('YYYY-MM-DD')
  );

  const periodDayMap = _.zipObject(
    periodDays,
    periodDays.map(d => ({ day: d, medium: 0, high: 0 }))
  );

  return _.merge(periodDayMap, activityPerDay);
};

const avgSedPerDay = (from, to, data) => {
  var sedPerDay = _.map(data, d => ({
    day: moment(d.date).format('YYYY-MM-DD'),
    sed: parseInt((d.sedentary * 100) / d.dayDuration),
    steps: parseInt(d.steps)
  }));

  sedPerDay = _.filter(sedPerDay, d => d.steps >= STEP_FILTER);

  sedPerDay = _(sedPerDay)
    .groupBy('day')
    .map((v, k) => ({
      day: k,
      sed: Math.round(_.meanBy(v, 'sed'))
    }))
    .value();

  sedPerDay = _.zipObject(
    _.map(sedPerDay, 'day'),
    _.map(sedPerDay, v => v)
  );

  const timezone = momentTz.tz.guess();
  const periodRange = moment.range(from, to);
  const periodDays = Array.from(periodRange.by('days')).map(d =>
    d.tz(timezone).format('YYYY-MM-DD')
  );
  const periodDayMap = _.zipObject(
    periodDays,
    periodDays.map(d => ({ day: d }))
  );

  return _.merge(periodDayMap, sedPerDay);
};

export const getTrackerPeriodDetails = (
  periodId,
  from,
  to,
  callback = undefined
) => async dispatch => {
  const timezone = momentTz.tz.guess();

  const fromArgument = from.tz(timezone);
  const fromArgumentString = from.format('YYYY-MM-DDTHH:mm:ss');
  const fromOffset = fromArgument.format('ZZ');
  const fromParam = fromArgumentString + fromOffset;

  const toArgument = to.tz(timezone);
  const toArgumentString = to.format('YYYY-MM-DDTHH:mm:ss');
  const toOffset = toArgument.format('ZZ');
  const toParam = toArgumentString + toOffset;

  if (!callback) dispatch({ type: GET_TRACKER_PERIOD_DETAILS_REQUEST });

  const httpClient = new HTTPService().client;

  try {
    const response = await httpClient.get('tracker/period/aggregated', {
      params: {
        periodId,
        from: fromParam,
        to: toParam,
        timezone
      }
    });

    const schoolDayRes = await httpClient.get('tracker/period/aggregated', {
      params: {
        periodId,
        from: fromParam,
        to: toParam,
        type: 0,
        timezone
      }
    });

    const blackListedTrackerDateMap = ignoredTrackersPerDateMap(response.data);

    const detailedRes = await httpClient.get('tracker/period/details', {
      params: {
        periodId,
        from: fromParam,
        to: toParam,
        timezone
      }
    });

    if (!callback) {
      dispatch({
        type: GET_TRACKER_PERIOD_DETAILS_SUCCESS,
        result: response.data,
        avgActivityPerDay: avgLocationActivityDay(
          blackListedTrackerDateMap,
          detailedRes.data
        ).avgActivityPerDay,
        avgStepsPerDay: avgLocationStepsDay(
          blackListedTrackerDateMap,
          detailedRes.data
        ),
        totalStepsPerDay: stepsPerDay(from, to, response.data),
        trackerReport: trackerReport(from, to, response.data),
        trackerReportWithoutStepsFilter: trackerReportWithoutStepsFilter(
          from,
          to,
          response.data
        ),
        avgStepsPerHour: stepsPerHour(
          blackListedTrackerDateMap,
          detailedRes.data
        ),
        avgActivityPerHour: activityPerHour(
          blackListedTrackerDateMap,
          detailedRes.data
        ),
        avgActivitySpread: activitySpread(from, to, response.data),
        avgStepSpread: stepSpread(from, to, response.data),
        avgSedPerDay: avgSedPerDay(from, to, schoolDayRes.data),
        avgActivityGenderDay: avgActivityGenderDay(
          blackListedTrackerDateMap,
          detailedRes.data
        ),
        avgStepsGenderDay: avgStepsGenderDay(
          blackListedTrackerDateMap,
          detailedRes.data
        ),
        avgLocationActivityDay: avgLocationActivityDay(
          blackListedTrackerDateMap,
          detailedRes.data
        ).avgLocationActivityDay
      });
    } else {
      callback.func(stepsPerDay(from, to, response.data), callback.name);
    }
  } catch (error) {
    console.error(error);

    if (!callback)
      dispatch({
        type: GET_TRACKER_PERIOD_DETAILS_FAILURE,
        error
      });
  }
};

export const getGroupPeriods = (
  group,
  callback = undefined
) => async dispatch => {
  if (!callback) dispatch({ type: GET_ORGANIZATION_GROUP_PERIOD_REQUEST });

  const httpClient = new HTTPService().client;

  try {
    const response = await httpClient.get('organization/group/periods', {
      params: {
        groupId: group.value
      }
    });

    if (!callback) {
      dispatch({
        type: GET_ORGANIZATION_GROUP_PERIOD_SUCCESS,
        result: response.data
      });
    } else {
      callback.func(response.data, callback.name);
    }
  } catch (error) {
    console.error(error);

    if (!callback)
      dispatch({
        type: GET_ORGANIZATION_GROUP_PERIOD_FAILURE,
        payload: {
          errorMessage: 'Could not get organization group periods'
        }
      });
  }
};

export const getGroups = (
  organization,
  callback = undefined
) => async dispatch => {
  if (!callback) dispatch({ type: GET_ORGANIZATION_GROUPS_REQUEST });

  const httpClient = new HTTPService().client;

  try {
    const response = await httpClient.get('organization/groups', {
      params: {
        organizationId: organization.value
      }
    });

    if (!callback) {
      dispatch({
        type: GET_ORGANIZATION_GROUPS_SUCCESS,
        result: response.data
      });
    } else {
      callback.func(response.data, callback.name);
    }
  } catch (error) {
    console.error(error);

    if (!callback)
      dispatch({
        type: GET_ORGANIZATION_GROUPS_FAILURE,
        error
      });
  }
};

export const getOrganizations = () => async dispatch => {
  dispatch({ type: GET_ORGANIZATIONS_REQUEST });

  const httpClient = new HTTPService().client;

  try {
    const user = await httpClient.get('user');
    const response = await httpClient.get('organization', {
      params: {
        ownerId: user.data.id
      }
    });

    dispatch({
      type: GET_ORGANIZATIONS_SUCCESS,
      result: response.data
    });
  } catch (error) {
    console.error(error);

    dispatch({
      type: GET_ORGANIZATIONS_FAILURE,
      error
    });
  }
};

export const selectOrganization = organization => dispatch => {
  dispatch({
    type: SELECT_ORGANIZATION,
    organization
  });
  dispatch({ type: CLEAR_TRACKER_PERIOD_DETAILS });
  getGroups(organization)(dispatch);
};

export const selectOrganizationGroup = organizationGroup => dispatch => {
  dispatch({
    type: SELECT_ORGANIZATION_GROUP,
    organizationGroup
  });
  dispatch({ type: CLEAR_TRACKER_PERIOD_DETAILS });
  getGroupPeriods(organizationGroup)(dispatch);
};

export const selectOrganizationGroupPeriod = (
  organizationGroupPeriod,
  willLoadDetails = true
) => dispatch => {
  dispatch({
    type: SELECT_ORGANIZATION_GROUP_PERIOD,
    organizationGroupPeriod
  });

  if (willLoadDetails)
    getTrackerPeriodDetails(
      organizationGroupPeriod.value,
      moment(organizationGroupPeriod.startTimestamp).utc(),
      moment(organizationGroupPeriod.endTimestamp).utc()
    )(dispatch);
};

export const getSummaryStatistic = () => async dispatch => {
  dispatch({ type: GET_SUMMARY_STATISTIC_REQUEST });

  const httpClient = new HTTPService().client;

  try {
    const response = await httpClient.get('organization/results', {
      params: {
        timestamp: '2020-08-01'
      }
    });

    dispatch({
      type: GET_SUMMARY_STATISTIC_SUCCESS,
      result: response.data
    });
  } catch (error) {
    console.error(error);

    dispatch({
      type: GET_SUMMARY_STATISTIC_FAILURE,
      error
    });
  }
};

export const selectPredefinedFields = () => dispatch => {
  const fields = {
    organization: JSON.parse(localStorage.getItem('class1_organization')),
    organizationGroup: JSON.parse(localStorage.getItem('class1_group')),
    organizationGroupPeriod: JSON.parse(localStorage.getItem('class1_period'))
  };
  dispatch({
    type: SELECT_PREDEFINED_FIELDS,
    fields
  });
  getTrackerPeriodDetails(
    fields.organizationGroupPeriod.value,
    moment(fields.organizationGroupPeriod.startTimestamp).utc(),
    moment(fields.organizationGroupPeriod.endTimestamp).utc()
  )(dispatch);
};
