import { ResponsiveBar } from '@nivo/bar';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BarLegend } from '../../../helpFunctions/graphLayers';
import styles from './organizations.module.scss';

const LocationActivityPerDayChart = ({ data }) => {
  const { t } = useTranslation();
  const [keys, setKeys] = useState(['Skoltid', 'Fritid']);

  return (
    <div className={`card ${styles['graph']}`}>
      <header className="card-header">
        <p className="card-header-title">
          {t('activity.physicalActivityLeisure')}
        </p>
      </header>
      <div className={`card-content ${styles['graph-content']}`}>
        <ResponsiveBar
          colors={b => {
            if (b.id === 'Fritid') {
              return '#faca71';
            }

            return '#2bc996';
          }}
          margin={{
            top: 10,
            right: 110,
            bottom: 70,
            left: 65
          }}
          data={data}
          keys={keys}
          axisLeft={{
            legend: 'min',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          axisBottom={{
            tickRotation: 40
          }}
          indexBy="day"
          className={`${styles['graph']}`}
          groupMode="grouped"
          layers={['grid', 'axes', 'bars', BarLegend]}
          legends={[
            {
              dataFrom: 'keys',
              data: keys.map(id => ({
                color: id === 'Fritid' ? '#faca71' : '#2bc996',
                id,
                label:
                  id === 'Skoltid'
                    ? t('activity.school_time')
                    : t('activity.leisure_time')
              })),
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 16
                }
              }
            },
            labels: {
              text: {
                fontSize: 16
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default LocationActivityPerDayChart;
