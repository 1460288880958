import React from 'react';

import styles from '../activityView.module.scss';
import i18n from '../../../../utils/i18n';

const NoData = ({ setGroupName, period }) => {
  // If user indicated dates
  if (period) {
    const { fromDate, toDate } = period;

    return (
      <p className={`${styles['container']} mt50 mb30`}>
        {i18n.t('activity.noData')} {setGroupName}{' '}
        {i18n.t('activity.withSuchDates')}: {fromDate} &mdash; {toDate}
      </p>
    );
  }

  return (
    <p className={`${styles['container']} mt50 mb30`}>
      {i18n.t('activity.noData')} {setGroupName}.
    </p>
  );
};

export default NoData;
