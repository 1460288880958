import {
  GET_ORGANIZATIONS_REQUEST,
  GET_ORGANIZATIONS_FAILURE,
  GET_ORGANIZATIONS_SUCCESS,
  GET_GROUPS_NAMES_FAILURE,
  GET_GROUPS_NAMES_REQUEST,
  GET_GROUPS_NAMES_SUCCESS,
  GET_TRACKER_REPORT_FAILURE,
  GET_TRACKER_REPORT_SUCCESS,
  GET_TRACKER_REPORT_REQUEST,
  CLEAR_TRACKER_REPORT,
  SET_GROUP_NAME,
  SET_PERIOD,
  SELECT_ORGANIZATION,
  GET_ORGANIZATION_GROUPS_REQUEST,
  GET_ORGANIZATION_GROUPS_SUCCESS,
  GET_ORGANIZATION_GROUPS_FAILURE,
  SELECT_ORGANIZATION_GROUP,
  GET_ORGANIZATION_GROUP_PERIOD_REQUEST,
  GET_ORGANIZATION_GROUP_PERIOD_SUCCESS,
  GET_ORGANIZATION_GROUP_PERIOD_FAILURE,
  SELECT_ORGANIZATION_GROUP_PERIOD,
  GET_TRACKER_PERIOD_DETAILS_FAILURE,
  GET_TRACKER_PERIOD_DETAILS_SUCCESS,
  GET_TRACKER_PERIOD_DETAILS_REQUEST,
  CLEAR_TRACKER_PERIOD_DETAILS,
  GET_SUMMARY_STATISTIC_REQUEST,
  GET_SUMMARY_STATISTIC_SUCCESS,
  GET_SUMMARY_STATISTIC_FAILURE,
  SELECT_PREDEFINED_FIELDS
} from '../../actions/actionTypes';

import {
  countData,
  filterDatesReport,
  filterTrackersReport,
  countFreshest
} from './helpers';

import _ from 'lodash';

const initialState = {
  error: false,
  errorMessage: null,
  loading: false,
  groupsNames: null,
  report: null,
  trackersReport: null,
  filtReport: null,
  filtTrackersReport: null,
  period: null,
  setGroupName: null,
  organizations: {
    data: [],
    loading: false,
    error: undefined
  },
  organizationGroups: {
    data: [],
    loading: false,
    error: undefined
  },
  organizationGroupPeriods: {
    data: [],
    loading: false,
    error: undefined
  },
  selectedOrganization: undefined,
  selectedOrganizationGroup: undefined,
  selectedOrganizationGroupPeriod: undefined,
  organizationGroupPeriodTrackerDetails: {
    loading: false,
    avgActivityPerDay: undefined,
    avgActivityPerHour: undefined,
    avgActivitySpread: undefined,
    avgStepSpread: undefined,
    avgSedPerDay: undefined,
    avgLocationActivityDay: undefined,
    avgActivityGenderDay: undefined
  },
  summaryStatistic: {
    data: [],
    loading: false,
    error: undefined
  }
};

const trackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUPS_NAMES_REQUEST:
      return {
        ...state,
        error: false,
        loading: true
      };

    case GET_GROUPS_NAMES_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        groupsNames: _(action.payload.groupsNames)
          .sort()
          .value()
      };

    case GET_GROUPS_NAMES_FAILURE:
    case GET_TRACKER_REPORT_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage
      };

    case GET_TRACKER_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_TRACKER_REPORT_SUCCESS: {
      const { report, fromDate, toDate } = action.payload;

      // Don't add empty reports
      if (!report.length)
        return {
          ...state,
          loading: false,
          report: {}
        };

      const { groupName, dataVersion, data } = countFreshest(report);
      const { text } = data;

      // Some data objects are empty
      if (!text)
        return {
          ...state,
          loading: false,
          report: {}
        };

      let { dataByDates, trackersReport } = countData(
        dataVersion,
        text,
        fromDate,
        toDate
      );

      // For text === 'dummy' there's no data
      if (!dataByDates)
        return {
          ...state,
          loading: false,
          report: {}
        };

      // Don't handle total data
      if (!groupName)
        return {
          ...state,
          loading: false,
          report: {}
        };

      // Skips data without real data (steps)
      const hasSteps = day => day.totalSteps !== 0;
      if (!dataByDates.some(hasSteps)) {
        return {
          ...state,
          loading: false,
          report: {}
        };
      }

      return {
        ...state,
        loading: false,
        report: { [groupName]: dataByDates },
        trackersReport
      };
    }

    case CLEAR_TRACKER_REPORT:
      return {
        ...state,
        report: null,
        loading: false,
        error: false,
        setGroupName: null
      };

    case SET_GROUP_NAME:
      return {
        ...state,
        setGroupName: action.payload.setGroupName
      };

    case SET_PERIOD:
      if (action.payload.period) {
        return {
          ...state,
          period: action.payload.period,
          filtReport: filterDatesReport(
            { ...state.report },
            action.payload.period
          ),
          filtTrackersReport: filterTrackersReport(
            [...state.trackersReport],
            action.payload.period
          )
        };
      }

      // When user clear chosen dates
      return {
        ...state,
        period: null,
        filtReport: null,
        filtTrackersReport: null
      };

    case GET_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        organizations: {
          data: [],
          loading: true,
          error: undefined
        }
      };

    case GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: {
          data: action.result,
          loading: false,
          error: undefined
        }
      };

    case GET_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        organizations: {
          data: [],
          loading: false,
          error: action.error
        }
      };

    case GET_ORGANIZATION_GROUPS_REQUEST:
      return {
        ...state,
        organizationGroups: {
          data: [],
          loading: true,
          error: undefined
        }
      };

    case GET_ORGANIZATION_GROUPS_SUCCESS:
      return {
        ...state,
        organizationGroups: {
          data: action.result,
          loading: false,
          error: undefined
        }
      };

    case GET_ORGANIZATION_GROUPS_FAILURE:
      return {
        ...state,
        organizationGroups: {
          data: [],
          loading: false,
          error: action.error
        }
      };

    case GET_ORGANIZATION_GROUP_PERIOD_REQUEST:
      return {
        ...state,
        organizationGroupPeriods: {
          data: [],
          loading: true,
          error: undefined
        }
      };

    case GET_ORGANIZATION_GROUP_PERIOD_SUCCESS:
      return {
        ...state,
        organizationGroupPeriods: {
          data: action.result,
          loading: false,
          error: undefined
        }
      };

    case GET_ORGANIZATION_GROUP_PERIOD_FAILURE:
      return {
        ...state,
        organizationGroupPeriods: {
          data: [],
          loading: false,
          error: action.error
        }
      };

    case GET_TRACKER_PERIOD_DETAILS_REQUEST:
      return {
        ...state,
        organizationGroupPeriodTrackerDetails: {
          ...state.organizationGroupPeriodTrackerDetails,
          loading: true
        }
      };

    case GET_TRACKER_PERIOD_DETAILS_SUCCESS:
      return {
        ...state,
        organizationGroupPeriodTrackerDetails: {
          ...state.organizationGroupPeriodTrackerDetails,
          loading: false,
          avgActivityPerDay: action.avgActivityPerDay,
          avgStepsPerDay: action.avgStepsPerDay,
          totalStepsPerDay: action.totalStepsPerDay,
          trackerReport: action.trackerReport,
          trackerReportWithoutStepsFilter:
            action.trackerReportWithoutStepsFilter,
          avgStepsPerHour: action.avgStepsPerHour,
          avgActivityPerHour: action.avgActivityPerHour,
          avgActivitySpread: action.avgActivitySpread,
          avgStepSpread: action.avgStepSpread,
          avgSedPerDay: action.avgSedPerDay,
          avgActivityGenderDay: action.avgActivityGenderDay,
          avgStepsGenderDay: action.avgStepsGenderDay,
          avgLocationActivityDay: action.avgLocationActivityDay
        }
      };

    case GET_TRACKER_PERIOD_DETAILS_FAILURE:
      return {
        ...state,
        organizationGroupPeriodTrackerDetails: {
          ...state.organizationGroupPeriodTrackerDetails,
          loading: false
        }
      };

    case SELECT_ORGANIZATION:
      return {
        ...state,
        selectedOrganization: action.organization,
        selectedOrganizationGroup: undefined,
        selectedOrganizationGroupPeriod: undefined,
        organizationGroups: {
          data: [],
          loading: false
        },
        organizationGroupPeriods: {
          data: [],
          loading: false
        }
      };

    case SELECT_ORGANIZATION_GROUP:
      return {
        ...state,
        selectedOrganizationGroup: action.organizationGroup,
        selectedOrganizationGroupPeriod: undefined,
        organizationGroupPeriods: {
          data: [],
          loading: false
        }
      };

    case SELECT_ORGANIZATION_GROUP_PERIOD:
      return {
        ...state,
        selectedOrganizationGroupPeriod: action.organizationGroupPeriod
      };

    case CLEAR_TRACKER_PERIOD_DETAILS:
      return {
        ...state,
        organizationGroupPeriodTrackerDetails: {
          loading: false,
          avgActivityPerDay: undefined,
          avgActivityPerHour: undefined,
          avgActivitySpread: undefined,
          avgStepSpread: undefined,
          avgSedPerDay: undefined,
          avgLocationActivityDay: undefined,
          avgActivityGenderDay: undefined
        }
      };
    case GET_SUMMARY_STATISTIC_REQUEST:
      return {
        ...state,
        summaryStatistic: {
          data: [],
          loading: true,
          error: undefined
        }
      };

    case GET_SUMMARY_STATISTIC_SUCCESS:
      return {
        ...state,
        summaryStatistic: {
          data: action.result,
          loading: false,
          error: undefined
        }
      };

    case GET_SUMMARY_STATISTIC_FAILURE:
      return {
        ...state,
        summaryStatistic: {
          data: [],
          loading: false,
          error: action.error
        }
      };

    case SELECT_PREDEFINED_FIELDS:
      return {
        ...state,
        selectedOrganization: action.fields.organization,
        selectedOrganizationGroup: action.fields.organizationGroup,
        selectedOrganizationGroupPeriod: action.fields.organizationGroupPeriod
      };

    default:
      return state;
  }
};

export default trackerReducer;
