import React, { Component } from 'react';
import styles from './organizations.module.scss';
import Select from 'react-select';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import AvgActivityPerDayChart from './AvgActivityPerDayChart';
import AvgStepsPerDayChart from './AvgStepsPerDayChart';
import AvgStepsPerHourChart from './AvgStepsPerHourChart';
import AvgActivityPerHourChart from './AvgActivityPerHourChart';
import AvgActivityDistributionChart from './AvgActivityDistributionChart';
import AvgStepDistributionChart from './AvgStepDistributionChart';
import AvgSedPerDayChart from './AvgSedPerDayChart';
import GenderActivityPerDayChart from './GenderActivityPerDayChart';
import GenderStepsPerDayChart from './GenderStepsPerDayChart';
import LocationActivityPerDayChart from './LocationActivityPerDayChart';
import Table from '../activity/children/table';
import { Spinner } from '../../general/loader/loader';
import SummaryStatistics from './SummaryStatistics';
import { withTranslation } from 'react-i18next';

class ChooseOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exportDisabled: false
    };
    this.pdfRef = React.createRef();
  }

  componentWillUnmount() {
    this.props.selectOrganization('');
  }

  getPDF = () => {
    this.setState({ exportDisabled: true });

    const { offsetWidth, offsetHeight } = this.pdfRef.current;
    const pdfWidth = 595.276;
    const pdfHeight = 841.8898;
    const topLeftMargin = pdfWidth * 0.06;
    const canvasImgWidth = pdfWidth - 2 * topLeftMargin;
    const canvasImgHeight = offsetHeight * (canvasImgWidth / offsetWidth);
    const totalPDFPages = Math.ceil(
      canvasImgHeight / (pdfHeight - 2 * topLeftMargin)
    );

    html2canvas(this.pdfRef.current).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/jpeg');
      let pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight], true);

      for (let i = 0; i < totalPDFPages; i++) {
        if (i > 0) pdf.addPage();

        pdf.addImage(
          contentDataURL,
          'JPEG',
          topLeftMargin,
          -(pdfHeight * i) + topLeftMargin * (2 * i + 1),
          canvasImgWidth,
          canvasImgHeight,
          undefined
        );

        pdf.setFillColor(255, 255, 255);
        pdf.rect(0, 0, pdfWidth, topLeftMargin, 'F');
        pdf.rect(0, pdfHeight - topLeftMargin, pdfWidth, topLeftMargin, 'F');
      }

      pdf.save(
        `${this.props.selectedOrganization.label.replace(' ', '_')}.pdf`
      );
      this.setState({ exportDisabled: false });
    });
  };

  render() {
    const {
      organizations,
      isOrganizationsLoading,
      selectOrganization,
      selectedOrganization,
      organizationGroups,
      isOrganizationGroupsLoading,
      selectedOrganizationGroup,
      selectOrganizationGroup,
      organizationGroupPeriods,
      isOrganizationGroupPeriodsLoading,
      selectedOrganizationGroupPeriod,
      selectOrganizationGroupPeriod,
      isOrganizationGroupPeriodTrackerDetailsLoading,
      avgActivityPerDay,
      avgStepsPerDay,
      trackerReport,
      avgStepsPerHour,
      avgActivityPerHour,
      avgActivitySpread,
      avgStepSpread,
      avgSedPerDay,
      avgActivityGenderDay,
      avgStepsGenderDay,
      avgLocationActivityDay,
      summaryStatistic,
      t
    } = this.props;

    const organizationOptions = organizations.map(o => ({
      label: o.name,
      value: o.organizationId
    }));

    const groupOptions = organizationGroups.map(o => ({
      label: o.name,
      value: o.groupId
    }));

    const formatTs = ts => moment(ts).format('YYYY-MM-DD');
    const weekFormatTs = ts => moment(ts).format('W');
    const durationTs = (s, e) =>
      moment(formatTs(e)).diff(moment(formatTs(s)), 'weeks');

    const periodOptions = organizationGroupPeriods
      .filter(
        el => !el.deleted && durationTs(el.startTimestamp, el.endTimestamp) <= 4
      )
      .map(o => ({
        label: `v${weekFormatTs(o.startTimestamp)}, ${formatTs(
          o.startTimestamp
        )} - ${formatTs(o.endTimestamp)}`,
        value: o.periodId,
        ...o
      }));

    return (
      <div>
        <div className={`${styles['card']} card mt30`}>
          <div className="card-content">
            <form>
              <div className="field">
                <label htmlFor="#groupName" className="label mb20">
                  {t('setGroup.school')}
                </label>
              </div>
              <div className="field">
                <Select
                  isLoading={isOrganizationsLoading}
                  value={selectedOrganization || ''}
                  onChange={selectedOrganization =>
                    selectOrganization(selectedOrganization)
                  }
                  options={organizationOptions}
                  isSearchable
                  isClearable
                  placeholder={t('setGroup.school')}
                  noOptionsMessage={({ inputValue }) => 'Inga skolor hittade'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#94e9e6',
                      primary: '#00d3ca'
                    }
                  })}
                />
              </div>
              <div className="field">
                <Select
                  isLoading={isOrganizationGroupsLoading}
                  value={selectedOrganizationGroup || ''}
                  onChange={selectedOrganizationGroup =>
                    selectOrganizationGroup(selectedOrganizationGroup)
                  }
                  options={groupOptions}
                  isSearchable
                  placeholder={t('setGroup.class')}
                  noOptionsMessage={({ inputValue }) => 'Inga klasser hittade'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#94e9e6',
                      primary: '#00d3ca'
                    }
                  })}
                />
              </div>
              <div className="field">
                <Select
                  isLoading={isOrganizationGroupPeriodsLoading}
                  value={selectedOrganizationGroupPeriod || ''}
                  onChange={selectedOrganizationGroupPeriod =>
                    selectOrganizationGroupPeriod(
                      selectedOrganizationGroupPeriod
                    )
                  }
                  options={periodOptions}
                  isSearchable
                  placeholder={t('setGroup.measurement_period')}
                  noOptionsMessage={({ inputValue }) => 'Inga perioder hittade'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#94e9e6',
                      primary: '#00d3ca'
                    }
                  })}
                />
              </div>
            </form>
          </div>
        </div>
        {/* {trackerReport && (
          <div className="text-center">
            <button disabled={this.state.exportDisabled} onClick={this.getPDF} className="button is-primary mt-3">
              Export PDF
            </button>
          </div>
        )} */}
        <div ref={this.pdfRef}>
          {!!summaryStatistic.length && (
            <SummaryStatistics {...this.props} periods={summaryStatistic} />
          )}
          {!isOrganizationGroupPeriodTrackerDetailsLoading ? (
            <>
              <div className={`${styles['graph-wrapper']}`}>
                {avgActivityPerDay && (
                  <AvgActivityPerDayChart
                    keys={Object.keys(avgActivityPerDay)}
                    data={Object.values(avgActivityPerDay)}
                  />
                )}
                {avgStepsPerDay && (
                  <AvgStepsPerDayChart
                    keys={Object.keys(avgStepsPerDay)}
                    data={Object.values(avgStepsPerDay)}
                  />
                )}
                {avgActivityPerHour && (
                  <AvgActivityPerHourChart data={avgActivityPerHour} />
                )}
                {avgStepsPerHour && (
                  <AvgStepsPerHourChart data={avgStepsPerHour} />
                )}
                {avgActivitySpread && (
                  <AvgActivityDistributionChart data={avgActivitySpread} />
                )}
                {avgStepSpread && (
                  <AvgStepDistributionChart data={avgStepSpread} />
                )}
                {avgActivityGenderDay && (
                  <GenderActivityPerDayChart data={avgActivityGenderDay} />
                )}
                {avgStepsGenderDay && (
                  <GenderStepsPerDayChart data={avgStepsGenderDay} />
                )}
                {avgSedPerDay && (
                  <AvgSedPerDayChart data={Object.values(avgSedPerDay)} />
                )}
                {avgLocationActivityDay && (
                  <LocationActivityPerDayChart data={avgLocationActivityDay} />
                )}
              </div>
              {trackerReport && (
                <Table trackersReport={Object.values(trackerReport)} />
              )}
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ChooseOrganization);
