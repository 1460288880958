import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../utils/i18n';

class LoginPhoneNumberForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: 'SE',
      phoneNumber: ''
    };
  }

  onCountryCodeChange = e => {
    this.setState({ countryCode: e.target.value });
  };

  onPhoneNumberChange = e => {
    this.setState({ phoneNumber: e.target.value });
  };

  onFormSubmit = e => {
    e.preventDefault();
    this.props.onSubmit(this.state.countryCode, this.state.phoneNumber);
  };

  render() {
    const { loading, error } = this.props;

    let buttonClassName = 'button is-fullwidth is-primary';
    if (loading) {
      buttonClassName += ' is-loading';
    }

    return (
      <Fragment>
        {error && (
          <div className="notification is-danger">
            {i18n.t('error.default')}
          </div>
        )}
        <form onSubmit={this.onFormSubmit}>
          <div className="field">{this.renderCountryDropdown()}</div>
          <div className="field">{this.renderPhoneNumberInput()}</div>
          <div className="field">
            <div className="control has-icons-left mt30">
              <button className={buttonClassName}>
                {i18n.t('login.loginButton')}
              </button>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }

  renderCountryDropdown = () => {
    return (
      <div>
        <label className="label mb20">{i18n.t('login.enterMobile')}</label>
        <div className="control has-icons-left">
          <div className="icon is-small is-left">
            <i className="fas fa-globe" />
          </div>
        </div>
      </div>
    );
  };

  renderPhoneNumberInput = () => {
    return (
      <div>
        <div className="control has-icons-left">
          <input
            className="input"
            onChange={this.onPhoneNumberChange}
            type="text"
            placeholder={i18n.t('login.phonePlaceholder')}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-mobile" />
          </span>
        </div>
      </div>
    );
  };
}

LoginPhoneNumberForm.propTypes = {
  countryCode: PropTypes.string,
  phoneNumber: PropTypes.string
};

export default LoginPhoneNumberForm;
