import axios from 'axios';
import axiosRetry from 'axios-retry';

const baseURL = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_X_API_KEY;

class HTTPService {
  constructor() {
    this.client = axios.create({
      baseURL: baseURL,
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('AT')}`
      }
    });
    axiosRetry(this.client, {
      retries: 1,
      retryCondition: error => {
        return error.response.status === 504;
      }
    });
  }
}
export default HTTPService;
