import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../../actions/authActions';

import styles from './topbar.module.scss';
import flagSV from '../../../resources/assets/icons/se.svg';
import flagEN from '../../../resources/assets/icons/gb.svg';
import { withTranslation } from 'react-i18next';

class Topbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowDropdownLang: false,
      language: 'EN'
    };
    this.wrapperLangRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const lang = this.props.i18n.language;

    switch (lang) {
      case 'en':
      case 'sv':
        this.setState({ language: lang.toUpperCase() });
        break;

      default:
        this.setState({ language: 'EN' });
        break;
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (
      this.wrapperLangRef.current &&
      !this.wrapperLangRef.current.contains(e.target)
    ) {
      this.setState({
        isShowDropdownLang: false
      });
    }
  };

  changeLang = lang => {
    this.props.i18n.changeLanguage(lang);
    this.setState({ language: lang.toUpperCase(), isShowDropdownLang: false });
  };

  render() {
    const { title, subtitle, logout, t } = this.props;
    const { isShowDropdownLang, language } = this.state;

    return (
      <header className={`${styles['topbar-container']}`}>
        <Link to="/main" className={`${styles['topbar-logo']}`}>
          SCRIIN.
        </Link>
        <Link to="/main" className={`${styles['topbar-link']}`}>
          {t('header.statistics')}
        </Link>
        <Link to="/step-map" className={`${styles['topbar-link']} mt-4`}>
          {t('map.stepmap')}
        </Link>
        <Link
          to="/physical-activity"
          className={`${styles['topbar-link']} mt-5`}
        >
          {t('header.peClass')}
        </Link>
        <div>
          <h1 className={`${styles['topbar-title']}`}>{title}</h1>
          {subtitle && (
            <p className={`${styles['topbar-subtitle']}`}>{subtitle}</p>
          )}
        </div>
        <div className={`${styles['topbar-lang-wrapper']}`}>
          <div
            onClick={() =>
              this.setState({ isShowDropdownLang: !isShowDropdownLang })
            }
            className={`${styles['topbar-lang']}`}
            id="lang-switcher"
          >
            {language === 'EN' && (
              <>
                <img
                  src={flagEN}
                  alt="EN"
                  className={`${styles['topbar-lang-flag']} mr-2`}
                />
                english
              </>
            )}
            {language === 'SV' && (
              <>
                <img
                  src={flagSV}
                  alt="SV"
                  className={`${styles['topbar-lang-flag']} mr-2`}
                />
                Svenska
              </>
            )}

            <svg
              id="lang-arrow"
              width="20"
              height="28"
              viewBox="0 0 16 24"
              fill="#4a4a4a"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.7"
                d="M4 10L8 14L12 10"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {isShowDropdownLang && (
            <div
              ref={this.wrapperLangRef}
              className={`${styles['topbar-dropdown']}`}
              style={{ width: '130px' }}
            >
              <p
                onClick={() => this.changeLang('sv')}
                className="d-flex align-items-center"
              >
                <img src={flagSV} alt="SV" className="mr-2" />
                Svenska
              </p>
              <p
                onClick={() => this.changeLang('en')}
                className="d-flex align-items-center"
              >
                <img src={flagEN} alt="EN" className="mr-2" />
                English
              </p>
            </div>
          )}
        </div>
        <button className={`${styles['topbar-logout']}`} onClick={logout}>
          {t('header.logout')}
        </button>
      </header>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(logout());
    }
  };
};

export default withTranslation()(connect(null, mapDispatchToProps)(Topbar));
