export const GET_OTP_REQUEST = 'GET_OTP_REQUEST';
export const GET_OTP_REQUEST_SUCCESS = 'GET_OTP_REQUEST_SUCCESS';
export const GET_OTP_REQUEST_FAILURE = 'GET_OTP_REQUEST_FAILURE';

export const VALIDATE_OTP_REQUEST = 'VALIDATE_OTP_REQUEST';
export const VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS';
export const VALIDATE_OTP_FAILURE = 'VALIDATE_OTP_FAILURE';

export const SET_ACCESSTOKEN = 'SET_ACCESSTOKEN';
export const REMOVE_ACCESSTOKEN = 'REMOVE_ACCESSTOKEN';
export const USER_LOGOUT = 'USER_LOGOUT';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';

export const GET_GROUP_DETAILS_REQUEST = 'GET_GROUPS_DETAILS_REQUEST';
export const GET_GROUP_DETAILS_SUCCESS = 'GET_GROUPS_DETAILS_SUCCESS';
export const GET_GROUP_DETAILS_FAILURE = 'GET_GROUPS_DETAILS_FAILURE';

export const SELECT_GROUP = 'SELECT_GROUP';
export const RESET_SELECTED_GROUP = 'RESET_SELECTED_GROUP';

export const SELECT_PREVIOUS_INTERVAL = 'SELECT_PREVIOUS_INTERVAL';
export const SELECT_NEXT_INTERVAL = 'SELECT_NEXT_INTERVAL';

export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';

export const GET_GROUPS_NAMES_FAILURE = 'GET_GROUPS_NAMES_FAILURE';
export const GET_GROUPS_NAMES_SUCCESS = 'GET_GROUPS_NAMES_SUCCESS';
export const GET_GROUPS_NAMES_REQUEST = 'GET_GROUPS_NAMES_REQUEST';

export const GET_TRACKER_REPORT_REQUEST = 'GET_TRACKER_REPORT_REQUEST';
export const GET_TRACKER_REPORT_SUCCESS = 'GET_TRACKER_REPORT_SUCCESS';
export const GET_TRACKER_REPORT_FAILURE = 'GET_TRACKER_REPORT_FAILURE';

export const CLEAR_TRACKER_REPORT = 'CLEAR_TRACKER_REPORT';

export const SET_GROUP_NAME = 'SET_GROUP_NAME';
export const SET_PERIOD = 'SET_PERIOD';

export const GET_ORGANIZATIONS_FAILURE = 'GET_ORGANIZATIONS_FAILURE';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_REQUEST = 'GET_ORGANIZATIONS_REQUEST';
export const SELECT_ORGANIZATION = 'SELECT_ORGANIZATION';

export const GET_ORGANIZATION_GROUPS_REQUEST =
  'GET_ORGANIZATION_GROUPS_REQUEST';
export const GET_ORGANIZATION_GROUPS_SUCCESS =
  'GET_ORGANIZATION_GROUPS_SUCCESS';
export const GET_ORGANIZATION_GROUPS_FAILURE =
  'GET_ORGANIZATION_GROUPS_FAILURE';
export const SELECT_ORGANIZATION_GROUP = 'SELECT_ORGANIZATION_GROUP';

export const GET_ORGANIZATION_GROUP_PERIOD_REQUEST =
  'GET_ORGANIZATION_GROUPS_PERIOD_REQUEST';
export const GET_ORGANIZATION_GROUP_PERIOD_SUCCESS =
  'GET_ORGANIZATION_GROUP_PERIOD_SUCCESS';
export const GET_ORGANIZATION_GROUP_PERIOD_FAILURE =
  'GET_ORGANIZATION_GROUP_PERIOD_FAILURE';
export const SELECT_ORGANIZATION_GROUP_PERIOD =
  'SELECT_ORGANIZATION_GROUP_PERIOD';

export const GET_TRACKER_PERIOD_DETAILS_REQUEST =
  'GET_TRACKER_PERIOD_DETAILS_REQUEST';
export const GET_TRACKER_PERIOD_DETAILS_SUCCESS =
  'GET_TRACKER_PERIOD_DETAILS_SUCCESS';
export const GET_TRACKER_PERIOD_DETAILS_FAILURE =
  'GET_TRACKER_PERIOD_DETAILS_FAILURE';
export const CLEAR_TRACKER_PERIOD_DETAILS = 'CLEAR_TRACKER_PERIOD_DETAILS';

export const GET_SUMMARY_STATISTIC_REQUEST = 'GET_SUMMARY_STATISTIC_REQUEST';
export const GET_SUMMARY_STATISTIC_SUCCESS = 'GET_SUMMARY_STATISTIC_SUCCESS';
export const GET_SUMMARY_STATISTIC_FAILURE = 'GET_SUMMARY_STATISTIC_FAILURE';

export const SELECT_PREDEFINED_FIELDS = 'SELECT_PREDEFINED_FIELDS';
