import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './organizations.module.scss';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import momentTz from 'moment-timezone';
import Table from '../activity/children/table';
import { Spinner } from '../../general/loader/loader';
import DatePicker from '../../general/datepicker/datepicker';
import { withTranslation } from 'react-i18next';

import { getTrackerPeriodDetails } from '../../../actions/trackerActions';

class ChooseOrganizationActivity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      startTime: moment()
        .set('hour', 9)
        .set('minute', 0)
        .toDate(),
      endTime: moment()
        .set('hour', 14)
        .set('minute', 30)
        .toDate()
    };
  }

  componentWillUnmount() {
    this.props.selectOrganization('');
  }

  handleDateField = (val, name) => {
    this.setState({ [name]: val });
  };

  clearDates = () => {
    this.setState({
      startDate: null,
      endDate: null
    });
  };

  handleSubmit = () => {
    const {
      selectedOrganizationGroupPeriod,
      getTrackerPeriodDetails
    } = this.props;
    const { startDate, endDate, startTime, endTime } = this.state;
    const startDateString = moment(startDate).format('YYYY-MM-DD');
    const startTimeString = moment(startTime).format('HH:mm:00');
    const endDateString = moment(endDate).format('YYYY-MM-DD');
    const endTimeString = moment(endTime).format('HH:mm:00');

    const timezone = momentTz.tz.guess();
    const from = moment(`${startDateString}T${startTimeString}`).tz(timezone);
    const to = moment(`${endDateString}T${endTimeString}`).tz(timezone);

    getTrackerPeriodDetails(selectedOrganizationGroupPeriod.value, from, to);
  };

  render() {
    const {
      organizations,
      isOrganizationsLoading,
      selectOrganization,
      selectedOrganization,
      organizationGroups,
      isOrganizationGroupsLoading,
      selectedOrganizationGroup,
      selectOrganizationGroup,
      organizationGroupPeriods,
      isOrganizationGroupPeriodsLoading,
      selectedOrganizationGroupPeriod,
      selectOrganizationGroupPeriod,
      isOrganizationGroupPeriodTrackerDetailsLoading,
      trackerReportWithoutStepsFilter,
      t
    } = this.props;
    const { startDate, endDate, startTime, endTime } = this.state;

    const organizationOptions = organizations.map(o => ({
      label: o.name,
      value: o.organizationId
    }));

    const groupOptions = organizationGroups.map(o => ({
      label: o.name,
      value: o.groupId
    }));

    const formatTs = ts => moment(ts).format('YYYY-MM-DD');
    const weekFormatTs = ts => moment(ts).format('W');
    const durationTs = (s, e) =>
      moment(formatTs(e)).diff(moment(formatTs(s)), 'weeks');

    const periodOptions = organizationGroupPeriods
      .filter(
        el => !el.deleted && durationTs(el.startTimestamp, el.endTimestamp) <= 4
      )
      .map(o => ({
        label: `v${weekFormatTs(o.startTimestamp)}, ${formatTs(
          o.startTimestamp
        )} - ${formatTs(o.endTimestamp)}`,
        value: o.periodId,
        ...o
      }));

    return (
      <div>
        <div className={`${styles['card']} card mt30`}>
          <div className="card-content">
            <form>
              <div className="field">
                <label htmlFor="#groupName" className="label mb20">
                  {t('setGroup.school')}
                </label>
              </div>
              <div className="field">
                <Select
                  isLoading={isOrganizationsLoading}
                  value={selectedOrganization || ''}
                  onChange={selectedOrganization => {
                    selectOrganization(selectedOrganization);
                    this.clearDates();
                  }}
                  options={organizationOptions}
                  isSearchable
                  placeholder={t('setGroup.school')}
                  noOptionsMessage={({ inputValue }) => 'Inga skolor hittade'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#94e9e6',
                      primary: '#00d3ca'
                    }
                  })}
                />
              </div>
              <div className="field">
                <Select
                  isLoading={isOrganizationGroupsLoading}
                  value={selectedOrganizationGroup || ''}
                  onChange={selectedOrganizationGroup => {
                    selectOrganizationGroup(selectedOrganizationGroup);
                    this.clearDates();
                  }}
                  options={groupOptions}
                  isSearchable
                  placeholder={t('setGroup.class')}
                  noOptionsMessage={({ inputValue }) => 'Inga klasser hittade'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#94e9e6',
                      primary: '#00d3ca'
                    }
                  })}
                />
              </div>
              <div className="field">
                <Select
                  isLoading={isOrganizationGroupPeriodsLoading}
                  value={selectedOrganizationGroupPeriod || ''}
                  onChange={selectedOrganizationGroupPeriod => {
                    selectOrganizationGroupPeriod(
                      selectedOrganizationGroupPeriod,
                      false
                    );
                    this.clearDates();
                  }}
                  options={periodOptions}
                  isSearchable
                  placeholder={t('setGroup.measurement_period')}
                  noOptionsMessage={({ inputValue }) => 'Inga perioder hittade'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#94e9e6',
                      primary: '#00d3ca'
                    }
                  })}
                />
              </div>
              <div className="field d-flex">
                <DatePicker
                  selected={startDate}
                  onChange={val => this.handleDateField(val, 'startDate')}
                  minDate={
                    moment(
                      selectedOrganizationGroupPeriod?.startTimestamp
                    ).toDate() || null
                  }
                  maxDate={
                    endDate ||
                    moment(
                      selectedOrganizationGroupPeriod?.endTimestamp,
                      'YYYYMMDDTHHmmss'
                    ).toDate()
                  }
                  dateFormat="yyyy-MM-dd"
                  onChangeRaw={e => e.preventDefault()}
                  placeholderText={t('dates.startDate')}
                  disabled={!selectedOrganizationGroupPeriod}
                />
                <div className={styles['time-field']}>
                  <DatePicker
                    selected={startTime}
                    onChange={val => this.handleDateField(val, 'startTime')}
                    dateFormat="HH:mm"
                    timeCaption="Starttid"
                    timeIntervals={5}
                    showTimeSelect
                    showTimeSelectOnly
                    customInput={
                      <MaskedInput
                        type="text"
                        mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                      />
                    }
                    disabled={!selectedOrganizationGroupPeriod}
                  />
                </div>
              </div>
              <div className="field d-flex">
                <DatePicker
                  selected={endDate}
                  onChange={val => this.handleDateField(val, 'endDate')}
                  minDate={
                    startDate ||
                    moment(
                      selectedOrganizationGroupPeriod?.startTimestamp
                    ).toDate()
                  }
                  maxDate={
                    moment(
                      selectedOrganizationGroupPeriod?.endTimestamp,
                      'YYYYMMDDTHHmmss'
                    ).toDate() || null
                  }
                  dateFormat="yyyy-MM-dd"
                  onChangeRaw={e => e.preventDefault()}
                  placeholderText={t('dates.endDate')}
                  disabled={!selectedOrganizationGroupPeriod}
                />
                <div className={styles['time-field']}>
                  <DatePicker
                    selected={endTime}
                    onChange={val => this.handleDateField(val, 'endTime')}
                    dateFormat="HH:mm"
                    timeCaption="Sluttid"
                    timeIntervals={5}
                    showTimeSelect
                    showTimeSelectOnly
                    customInput={
                      <MaskedInput
                        type="text"
                        mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                      />
                    }
                    disabled={!selectedOrganizationGroupPeriod}
                  />
                </div>
              </div>
              <div className="field">
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  className="button is-fullwidth is-primary"
                  disabled={
                    !selectedOrganizationGroupPeriod ||
                    isOrganizationGroupPeriodsLoading ||
                    !startDate ||
                    !endDate
                  }
                >
                  {t('groups.showResults')}
                </button>
              </div>
            </form>
          </div>
        </div>
        {!isOrganizationGroupPeriodTrackerDetailsLoading ? (
          trackerReportWithoutStepsFilter && (
            <Table
              trackersReport={Object.values(trackerReportWithoutStepsFilter)}
              showAverages
            />
          )
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTrackerPeriodDetails: (periodId, from, to) => {
      dispatch(getTrackerPeriodDetails(periodId, from, to));
    }
  };
};

export default withTranslation()(
  connect(null, mapDispatchToProps)(ChooseOrganizationActivity)
);
