import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE
} from 'actions/actionTypes';

const initialState = {
  user: null,
  loading: false,
  error: false,
  errorMessage: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        loading: false,
        error: false
      };
    }
    case GET_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    }
    default:
      return state;
  }
};

export default userReducer;
