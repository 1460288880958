import React, { Fragment } from 'react';
import Pdf from '../../general/reactToPdf';
import { CSVLink } from 'react-csv';

import styles from './activityView.module.scss';
import i18n from '../../../utils/i18n';

import Loader from './../../../components/general/loader/loader';
import Charts from './children/charts';
import Table from './children/table';
import NoData from './children/noData';

const ref = React.createRef();
const ref2 = React.createRef();

const activityView = ({
  report,
  loading,
  setGroupName,
  trackersReport,
  period,
  expLoader,
  expStart,
  toggleLoader
}) => {
  if (loading) return <Loader />;
  if (!report) return null;

  const filename = `Activity_for_${setGroupName.replace(' ', '_')}`;
  const { fromDate, toDate } = period || {};

  let trackersReportToCSV = [];

  if (trackersReport)
    trackersReport.map(report => {
      trackersReportToCSV.push({ date: report.trackerId });

      report.dates.map((date, index) => {
        return trackersReportToCSV.push(date);
      });

      return trackersReportToCSV;
    });

  const headers = [
    { label: 'Date', key: 'date' },
    { label: 'Battery', key: 'battery' },
    { label: 'Steps', key: 'steps' },
    { label: 'L', key: 'low' },
    { label: 'M', key: 'medium' },
    { label: 'H', key: 'high' },
    { label: 'M+H', key: 'totalActMin' },
    { label: 'Sed', key: 'sed' }
  ];

  const options = {
    orientation: 'p',
    unit: 'mm',
    format: 'a4',
    compress: true
  };

  return (
    <div className={`${styles['main-container']}`}>
      <div className={`${styles['buttons-wrapper']}`}>
        <Pdf
          targetRef={ref}
          targetRef2={ref2}
          filename={`${filename}.pdf`}
          options={options}
          onComplete={() => toggleLoader()}
          x={5}
          y={5}
        >
          {({ toPdf }) => (
            <button
              className={`${styles['button']} button is-primary ml30`}
              onClick={() => expStart(toPdf)}
              disabled={expLoader}
            >
              {expLoader
                ? i18n.t('generic.loading')
                : i18n.t('activity.exportPdf')}
            </button>
          )}
        </Pdf>
        <CSVLink
          headers={headers}
          data={trackersReportToCSV}
          filename={`${filename}.csv`}
          className={`${styles['button']} button is-primary ml30`}
          style={{ textDecoration: 'none' }}
          target="_blank"
        >
          {i18n.t('activity.exportCsv')}
        </CSVLink>
      </div>

      <div className="mt60">
        {/* start of pdf */}
        <div
          style={{
            // Set paddings in PDF
            padding: '40px 40px',
            // Align container back in the browser
            margin: '-40px -40px'
          }}
        >
          {JSON.stringify(report) === '{}' ? (
            <NoData setGroupName={setGroupName} period={period} />
          ) : (
            <Fragment>
              <div ref={ref}>
                {period ? (
                  <h2 className={`${styles['title']}`}>
                    {`${i18n.t('activity.activityFor')} ${setGroupName}`}.{' '}
                    {i18n.t('calendar.timePeriod')}: {fromDate} &mdash; {toDate}
                  </h2>
                ) : (
                  <h2 className={`${styles['title']}`}>
                    {`${i18n.t('activity.activityFor')} ${setGroupName}`}
                  </h2>
                )}

                <Charts report={report} setGroupName={setGroupName} />
              </div>
              <div ref={ref2}>
                <Table trackersReport={trackersReport} />
              </div>
            </Fragment>
          )}
        </div>
        {/* end of pdf */}
      </div>
    </div>
  );
};

export default activityView;
