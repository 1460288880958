import React from 'react';

import SummaryTable from '../activity/children/summaryTable';
import styles from '../activity/activityView.module.scss';

export default function SummaryStatistics({
  selectedOrganization,
  selectedOrganizationGroup,
  selectedOrganizationGroupPeriod,
  organizations,
  periods
}) {
  const isAllSchoolsStatistic = !selectedOrganization;
  const isSchoolStatistic =
    selectedOrganization &&
    !selectedOrganizationGroup &&
    !selectedOrganizationGroupPeriod;
  const isGroupStatistic =
    selectedOrganization &&
    selectedOrganizationGroup &&
    !selectedOrganizationGroupPeriod;
  const isPeriodStatistic =
    selectedOrganization &&
    selectedOrganizationGroup &&
    selectedOrganizationGroupPeriod;

  if (isAllSchoolsStatistic) {
    return organizations.map((item, index) => {
      const data = periods.filter(
        p => p.organizationId === item.organizationId
      );

      return !!data.length ? (
        <div key={index} className={`${styles['container']} mt-4`}>
          <h1 className={`${styles['table-title']}`}>{item.name}</h1>
          <SummaryTable data={data} />
        </div>
      ) : null;
    });
  }
  if (isSchoolStatistic) {
    const data = periods.filter(
      item => item.organizationId === selectedOrganization.value
    );

    return !!data.length ? (
      <div className={`${styles['container']} mt-4`}>
        <h1 className={`${styles['table-title']}`}>
          {selectedOrganization.label}
        </h1>
        <SummaryTable data={data} />
      </div>
    ) : null;
  }
  if (isGroupStatistic) {
    const data = periods.filter(
      item => item.groupId === selectedOrganizationGroup.value
    );

    return !!data.length ? (
      <div className={`${styles['container']} mt-4`}>
        <h1 className={`${styles['table-title']}`}>
          {selectedOrganization.label}
        </h1>
        <SummaryTable data={data} />
      </div>
    ) : null;
  }
  if (isPeriodStatistic) {
    const data = periods.filter(
      item => item.periodId === selectedOrganizationGroupPeriod.value
    );

    return !!data.length ? (
      <div className={`${styles['container']} mt-4`}>
        <h1 className={`${styles['table-title']}`}>
          {selectedOrganization.label}
        </h1>
        <SummaryTable data={data} />
      </div>
    ) : null;
  }
}
