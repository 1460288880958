import React, { Component } from 'react';

import i18n from '../../../utils/i18n';
import styles from './setGroup.module.scss';
import Select from 'react-select';

const yearWeeks = [];

class SetGroup extends Component {
  constructor() {
    super();

    this.state = {
      selectedGroup: null,
      selectedWeek: null
    };
  }

  // to delete this at the end
  // componentDidMount() {
  //   this.props.getReport('Västertorpsskolan');
  //   this.props.setName('Västertorpsskolan');
  // }
  // // end fo to delete

  componentDidMount() {
    this.getYearWeeks();
  }

  handleChange = selectedGroup => {
    this.setState({ selectedGroup });
  };

  handleChangeWeek = selectedWeek => {
    this.setState({ selectedWeek });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { selectedGroup, selectedWeek } = this.state;
    const dateRange = selectedWeek.value.split(' ');

    this.props.getReport(selectedGroup.value, dateRange[0], dateRange[1]);
    this.props.setName(selectedGroup.value);
  };

  clearForm = e => {
    e.preventDefault();

    this.setState({ selectedGroup: null }, () => this.props.clearReport());
  };

  getYearWeeks = () => {
    const self = this;
    let curr = new Date(2020, 0, 1);
    let currDec = new Date(2020, 11, 1);
    currDec.setYear(currDec.getFullYear() - 1);
    let week = [];
    let weekCounter = this.getWeekNumber(currDec);
    counterDecember(weekCounter);
    week = [];
    weekCounter = 1;
    counter(weekCounter);

    function counter(weekNumber) {
      for (let i = 1; i <= 7; i++) {
        let currDay = curr.getDay() === 0 ? 7 : curr.getDay();
        let first = curr.getDate() - currDay + i;
        let day = new Date(curr.setDate(first + 1)).toISOString().slice(0, 10);
        week.push(day);
        if (i === 7)
          yearWeeks.push({
            label: `${i18n.t('setGroup.labelWeek')} ${weekNumber}`,
            value: `${week[0]} ${week[week.length - 1]}`
          });
      }

      if (
        weekNumber ===
        self.getWeekNumber(new Date(new Date().getFullYear(), 11, 31))
      )
        return yearWeeks;

      week = [];
      weekCounter = weekCounter + 1;
      curr.setDate(curr.getDate() + 1);
      counter(weekCounter);
    }

    function counterDecember(weekNumber) {
      for (let i = 1; i <= 7; i++) {
        let currDay = currDec.getDay() === 0 ? 7 : currDec.getDay();
        let first = currDec.getDate() - currDay + i;
        let day = new Date(currDec.setDate(first + 1))
          .toISOString()
          .slice(0, 10);
        week.push(day);
        if (i === 7)
          yearWeeks.push({
            label: `${i18n.t(
              'setGroup.labelWeek'
            )} ${weekNumber} (${currDec.getFullYear()})`,
            value: `${week[0]} ${week[week.length - 1]}`
          });
      }

      if (weekNumber === 52) return yearWeeks;

      week = [];
      weekCounter = weekCounter + 1;
      currDec.setDate(currDec.getDate() + 1);
      counterDecember(weekCounter);
    }
  };

  getWeekNumber = d => {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  };

  render() {
    const { selectedGroup, selectedWeek } = this.state;
    const { groupsNames } = this.props;

    const groupsOptions = groupsNames
      ? groupsNames.map(value => {
          return { value, label: value };
        })
      : [];

    return (
      <div className={`${styles['card']} card mt30`}>
        <div className="card-content">
          <form onSubmit={this.handleSubmit}>
            <div className="field">
              <label htmlFor="#groupName" className="label mb20">
                {i18n.t('setGroup.label')}
              </label>
            </div>
            <div className="field">
              <Select
                value={selectedGroup}
                onChange={this.handleChange}
                options={groupsOptions}
                isSearchable
                placeholder={i18n.t('setGroup.placeholder')}
                noOptionsMessage={({ inputValue }) =>
                  i18n.t('setGroup.notFound')
                }
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#94e9e6',
                    primary: '#00d3ca'
                  }
                })}
              />
            </div>
            <div className="field">
              <Select
                value={selectedWeek}
                onChange={this.handleChangeWeek}
                options={yearWeeks}
                isSearchable
                placeholder={i18n.t('setGroup.labelWeek')}
                noOptionsMessage={({ inputValue }) =>
                  i18n.t('setGroup.notFound')
                }
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#94e9e6',
                    primary: '#00d3ca'
                  }
                })}
              />
            </div>
            <div className="field">
              <button
                className="button is-fullwidth is-primary"
                disabled={!selectedGroup || !selectedWeek}
              >
                {i18n.t('dashboard.send')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SetGroup;
