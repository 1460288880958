import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import { connect } from 'react-redux';

import LoginContainer from './components/containers/login/loginContainer';
import DashboardContainer from './components/containers/dashboard/dashboardContainer';
import OrganizationContainer from './components/containers/organizations/organizationContainer';
import MainContainer from './components/containers/main/mainContainer';

import ChooseOrganization from './components/presentation/organizations/ChooseOrganization';
import ChooseOrganizationMap from './components/presentation/organizations/ChooseOrganizationMap';
import ChooseOrganizationActivity from './components/presentation/organizations/ChooseOrganizationActivity';

const googleMapsApiKey = 'AIzaSyBcvC-Bjjw6RxYcNKMtxBGdDdeHgDLbSj8';

class NotFound extends Component {
  render() {
    return (
      <div className="pt50 pb50 mx-auto" style={{ textAlign: 'center' }}>
        <h1 className="title">404</h1>
        <p className="subtitle">The page is not found</p>
        <button className="button mt40" onClick={() => window.history.back()}>
          Go back
        </button>
      </div>
    );
  }
}

const Organizations = () => (
  <MainContainer>
    <OrganizationContainer>
      <ChooseOrganization />
    </OrganizationContainer>
  </MainContainer>
);

const OrganizationsMap = () => (
  <MainContainer>
    <OrganizationContainer>
      <ChooseOrganizationMap
        googleMapURL={
          'https://maps.googleapis.com/maps/api/js?key=' +
          googleMapsApiKey +
          '&libraries=geometry,drawing,places&language=en'
        }
        loadingElement={<div style={{ height: `100%` }} />}
      />
    </OrganizationContainer>
  </MainContainer>
);

const OrganizationsActivity = () => (
  <MainContainer>
    <OrganizationContainer>
      <ChooseOrganizationActivity />
    </OrganizationContainer>
  </MainContainer>
);

class App extends Component {
  render() {
    const { isLoggedIn } = this.props;
    return (
      <Router>
        <div className="App">
          {!isLoggedIn ? (
            <Switch>
              <Route
                exact
                strict={false}
                path="/login"
                component={LoginContainer}
              />
              <Route path="/404" component={NotFound} />
              <Redirect to="/login" />
            </Switch>
          ) : (
            <Switch>
              <Route
                exact
                strict={false}
                path="/main"
                component={Organizations}
              />
              <Route
                exact
                strict={false}
                path="/dashboard"
                component={DashboardContainer}
              />
              <Route
                exact
                strict={false}
                path="/step-map"
                component={OrganizationsMap}
              />
              <Route
                exact
                strict={false}
                path="/physical-activity"
                component={OrganizationsActivity}
              />

              <Route path="/main/404" component={NotFound} />
              <Redirect to="/main" />
            </Switch>
          )}
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.accessToken !== null
  };
};

export default connect(mapStateToProps)(App);
