import React, { Component } from 'react';
import moment from 'moment';

import styles from '../activityView.module.scss';
import { numberWithSpaces } from '../../../../reducers/tracker/helpers';
import { withTranslation } from 'react-i18next';

class SummaryTable extends Component {
  render() {
    const { data, t } = this.props;
    const avgActivity = Math.round(
      data.reduce((a, b) => a + b.activeAverage, 0) / data.length
    );
    const avgSteps = Math.round(
      data.reduce((a, b) => a + b.stepsAverage, 0) / data.length
    );
    const sedAverage =
      data.reduce((a, b) => a + b.sedentaryAverage, 0) / data.length;
    const durationAverage =
      data.reduce((a, b) => a + b.schoolDayDurationAverage, 0) / data.length;
    const avgSedTime = Math.round((sedAverage * 100) / durationAverage);
    const avgStepsTotal = Math.round(
      data.reduce((a, b) => a + b.stepsTotal, 0) / data.length
    );

    return (
      <table
        className={`${styles['table']} table is-partwidth is-hoverable accordion`}
      >
        <thead>
          <tr>
            <th className={`is-dark ${styles['is-dark']} is-narrow`}>
              {t('setGroup.class')}
            </th>
            <th className={`is-dark ${styles['is-dark']} is-narrow`}>
              {t('setGroup.measurement_period')}
            </th>
            <th className={`is-dark ${styles['is-dark']} is-narrow`}>
              {t('groups.activity.title')}
            </th>
            <th className={`is-dark ${styles['is-dark']} is-narrow`}>
              {t('activity.steps')}
            </th>
            <th className={`is-dark ${styles['is-dark']} is-narrow`}>
              {t('activity.sedentary_time')}
              <br /> ({t('activity.during_day')})
            </th>
            <th className={`is-dark ${styles['is-dark']} is-narrow`}>
              {t('activity.totalStepsGroup')}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const startMoment = moment(item.periodStart).utc();
            const endMoment = moment(item.periodEnd).utc();
            const weekStart = startMoment.isoWeek();
            const weekEnd = endMoment.isoWeek();
            const yearStart = startMoment.format('YYYY');
            const yearEnd = endMoment.format('YYYY');
            let week = '';
            let year = '';
            let period = '';
            console.log('periodStart=', startMoment);
            console.log('periodEnd=', endMoment);

            if (yearStart === yearEnd) {
              year = yearStart;
              if (weekStart === weekEnd) {
                week = weekStart;
              } else {
                week = `${weekStart}-${weekEnd}`;
                console.log('weekStart=', weekStart);
                console.log('weekEnd=', weekEnd);
              }
              period = `v${week}, ${year}`;
            } else {
              period = `v${weekStart}, ${yearStart} - v${weekEnd}, ${yearEnd}`;
            }

            const sedentaryInPercent = Math.round(
              (item.sedentaryAverage * 100) / item.schoolDayDurationAverage
            );

            return (
              <tr key={`track-${index}`}>
                <td className="is-light">{item.groupName}</td>
                <td className="is-light">{period}</td>
                <td className="is-light">{item.activeAverage} min</td>
                <td className="is-light">{item.stepsAverage}</td>
                <td className="is-light">{sedentaryInPercent} %</td>
                <td className="is-light">
                  {numberWithSpaces(item.stepsTotal)}
                </td>
              </tr>
            );
          })}
          <tr>
            <td className="is-light">
              <strong>{t('activity.average')}</strong>
            </td>
            <td className="is-light" />
            <td className="is-light">
              <strong>{avgActivity} min</strong>
            </td>
            <td className="is-light">
              <strong>{avgSteps}</strong>
            </td>
            <td className="is-light">
              <strong>{avgSedTime} %</strong>
            </td>
            <td className="is-light">
              <strong>{numberWithSpaces(avgStepsTotal)}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default withTranslation()(SummaryTable);
