import { ResponsiveBar } from '@nivo/bar';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BarLegend } from '../../../helpFunctions/graphLayers';
import styles from './organizations.module.scss';

const GenderStepsPerDayChart = ({ data }) => {
  const { t } = useTranslation();
  const [keys, setKeys] = useState(['Flickor', 'Pojkar', 'Neutral']);

  return (
    <div className={`card ${styles['graph']}`}>
      <header className="card-header">
        <p className="card-header-title">{t('activity.stepsGirlsBoys')}</p>
      </header>
      <div className={`card-content ${styles['graph-content']}`}>
        <ResponsiveBar
          colors={b => {
            if (b.id === 'Pojkar') {
              return '#faf171';
            }
            if (b.id === 'Flickor') {
              return '#81d553';
            }
            return '#d8d8d8';
          }}
          margin={{
            top: 10,
            right: 110,
            bottom: 70,
            left: 65
          }}
          data={data}
          keys={keys}
          axisLeft={{
            legend: t('activity.steps_2'),
            legendPosition: 'middle',
            legendOffset: -60
          }}
          axisBottom={{
            tickRotation: 40
          }}
          indexBy="day"
          className={`${styles['graph']}`}
          groupMode="grouped"
          layers={['grid', 'axes', 'bars', BarLegend]}
          legends={[
            {
              dataFrom: 'keys',
              data: keys.map(id => ({
                color:
                  id === 'Pojkar'
                    ? '#faf171'
                    : id === 'Flickor'
                    ? '#81d553'
                    : '#d8d8d8',
                id,
                label:
                  id === 'Pojkar'
                    ? t('activity.boys')
                    : id === 'Flickor'
                    ? t('activity.girls')
                    : t('activity.neutral')
              })),
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 16
                }
              }
            },
            labels: {
              text: {
                fontSize: 12
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default GenderStepsPerDayChart;
