import React, { Component } from "react";
import Login from '../../../components/presentation/login/login';
import { connect } from "react-redux";
import { getOTP, validateOTP } from '../../../actions/otpActions';
import { Redirect } from "react-router-dom";

class LoginContainer extends Component {
  onPhoneNumberSubmit = (countryCode, phoneNumber) => {
    this.props.getOTP(countryCode, phoneNumber);
  };

  onOTPInputSubmit = (code, countryCode, phoneNumber) => {
    this.props.validateOTP(code, countryCode, phoneNumber);
  };

  render() {
    const { otp, user } = this.props;

    if (
      Boolean(otp.get.success) &&
      Boolean(user) &&
      Boolean(otp.validate.success)
    ) {
      return <Redirect to="/main" />;
    }

    return (
      <Login
        onPhoneNumberSubmit={this.onPhoneNumberSubmit}
        onOTPSubmit={this.onOTPInputSubmit}
        getOTPLoading={otp.get.loading}
        getOTPSuccess={otp.get.success}
        getOTPError={otp.get.error}
        validateOTPLoading={otp.validate.loading}
        validateOTPSuccess={otp.validate.success}
        validateOTPError={otp.validate.error}
      />
    );
  }
}

const mapStateToProps = ({ otp, user }) => {
  return {
    user: user.user,
    otp: {
      get: {
        loading: otp.get.loading,
        success: otp.get.success,
        error: otp.get.error,
        errorMessage: otp.get.errorMessage || 'Default error message'
      },
      validate: {
        loading: otp.validate.loading,
        success: otp.validate.success,
        error: otp.validate.error,
        errorMessage: otp.validate.errorMessage || 'Default error message'
      }
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOTP: (countryCode, mobile) => {
      dispatch(getOTP(countryCode, mobile));
    },
    validateOTP: (code, countryCode, mobile) => {
      dispatch(validateOTP(code, countryCode, mobile));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
