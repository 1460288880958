function lastInArr(arr) {
  return arr[arr.length - 1];
}

// function countFreshest(arr) {
//   const sorted = [...arr].sort((a, b) => new Date(a.date) - new Date(b.date));
//
//   return lastInArr(sorted);
// }

export function countData(dataVersion, text, fromDate, toDate) {
  // Handle raw text only for dataVersion: 1
  // if (dataVersion === 1) {
  return parseText(text, fromDate, toDate);
  // }
}

function parseText(text, fromDate, toDate) {
  if (text === 'dummy') return null;

  const data = text
    // Split by line break
    .split('\n')
    // Delete empty lines
    .filter(el => el);

  const totalIndex = data.findIndex(el => /Total/.test(el));
  const trackRecords = data.slice(1, totalIndex);
  const { trackers, dates } = parseTrackers(trackRecords, fromDate, toDate);

  return {
    dataByDates: countDataByDate(dates, trackers),
    trackersReport: countDataByTracker(trackers)
  };
}

function countDataByTracker(trackers) {
  return trackers.map(track => {
    const { trackerName, dates } = track;

    const totalSteps = dates.reduce((acc, curr) => {
      return acc + curr.steps;
    }, 0);
    const avrSteps = Math.round(totalSteps / dates.length);

    const totalLow = dates.reduce((acc, curr) => {
      return acc + curr.low;
    }, 0);
    const avrLow = Math.round(totalLow / dates.length);

    const totalMedHigh = dates.reduce((acc, curr) => {
      return acc + curr.totalActMin;
    }, 0);
    const avrMedHigh = Math.round(totalMedHigh / dates.length);

    const totalSed = dates.reduce((acc, curr) => {
      return acc + curr.sed;
    }, 0);
    const avrSedMin = new Date(Math.round(totalSed / dates.length));
    const avrSedHoursMin = `${Math.floor(avrSedMin / 60)}:${(avrSedMin % 60)
      .toString()
      .padStart(2, '0')}`;

    const trackerId = trackerName.replace(/0+/, '');

    return {
      trackerId,
      avrSteps,
      avrLow,
      avrMedHigh,
      avrSedHoursMin,
      dates
    };
  });
}

function parseTrackers(trackRecords, fromDate, toDate) {
  let trackers = [];
  const dates = [];

  trackRecords.forEach(record => {
    // If it's line with tracker name
    // If it's title record
    if (!/\d/.test(record)) return;

    if (!/\s/.test(record)) {
      trackers.push({
        trackerName: record,
        dates: []
      });
    } else {
      // Parse string to dates element
      const tracker = lastInArr(trackers);
      const {
        dateString,
        battery,
        steps,
        low,
        medium,
        high,
        totalActMin,
        sed
      } = parseTrackRecord(record);

      // Discard wrong dates
      if (new Date(dateString) < new Date(fromDate)) return;
      if (new Date(dateString) > new Date(toDate)) return;
      // Discard data without steps
      if (steps < 1000) return;
      if (steps === 0) return;

      tracker.dates.push({
        date: dateString,
        battery,
        steps,
        low,
        medium,
        high,
        totalActMin,
        sed
      });

      addDate(dateString, dates);
    }
  });

  // trackers = trackers.filter(item => item.dates.length);

  return { trackers, dates };
}

function parseTrackRecord(record) {
  const [
    dateString,
    battery,
    steps,
    low,
    medium,
    high,
    totalActMin,
    sed
  ] = record.split('\t');

  return {
    dateString,
    battery,
    steps: +steps,
    low: +low,
    medium: +medium,
    high: +high,
    totalActMin: +totalActMin,
    sed: +sed
  };
}

function addDate(dateString, dates) {
  if (!dates.includes(dateString)) {
    dates.push(dateString);
  }
}

function countDataByDate(dates, trackers) {
  let data = dates.map(date => ({ date: date }));
  data = addTotalData(data, trackers);
  data = addAverageData(data, trackers);

  return data;

  function addTotalData(data, trackers) {
    return data.map(el => {
      const { date } = el;

      let totalSteps = 0;
      let totalActMin = 0;
      let totalSedMin = 0;

      trackers.forEach(track => {
        const trackPerDate = track.dates.find(el => el.date === date);

        if (trackPerDate) {
          totalSteps += trackPerDate.steps;
          totalActMin += trackPerDate.totalActMin;
          totalSedMin += trackPerDate.sed;
        }
      });

      return { ...el, totalSteps, totalActMin, totalSedMin };
    });
  }

  function addAverageData(data, trackers) {
    return data.map(el => {
      const { date, totalSteps, totalActMin, totalSedMin } = el;

      const trackersNum = countTrackersNum(trackers, date);

      const avrSteps = trackersNum ? Math.round(totalSteps / trackersNum) : 0;
      const avrActMin = trackersNum ? Math.round(totalActMin / trackersNum) : 0;
      const avrSedMin = trackersNum ? Math.round(totalSedMin / trackersNum) : 0;
      const avrSedHoursMin = +`${Math.floor(avrSedMin / 60)}.${avrSedMin % 60}`;

      return { ...el, avrSteps, avrActMin, avrSedHoursMin };
    });
  }
}

function countTrackersNum(trackers, date) {
  let trackersNum = 0;

  trackers.forEach(track => {
    const trackPerDate = track.dates.find(el => el.date === date);

    // If total steps are not equal 0
    if (trackPerDate && trackPerDate.steps) {
      trackersNum++;
    }
  });

  return trackersNum;
}

export function filterDatesReport(report, { fromDate, toDate }) {
  const key = Object.keys(report)[0];
  const filtered = report[key].filter(el => {
    return (
      new Date(el.date) >= new Date(fromDate) &&
      new Date(el.date) <= new Date(toDate)
    );
  });

  if (filtered.length) {
    return {
      [key]: filtered
    };
  } else return {};
}

export function filterTrackersReport(report, { fromDate, toDate }) {
  return report.map(el => {
    const newDates = el.dates.filter(record => {
      return (
        new Date(record.date) >= new Date(fromDate) &&
        new Date(record.date) <= new Date(toDate)
      );
    });

    return {
      ...el,
      dates: newDates
    };
  });
}

export function countFreshest(arr) {
  const sorted = [...arr].sort((a, b) => new Date(a.date) - new Date(b.date));

  return lastInArr(sorted);
}

export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
