import { SHOW_SIDEBAR, HIDE_SIDEBAR } from './../actions/actionTypes';

const initialState = {
  sidebarOpen: false
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SIDEBAR: {
      return {
        sidebarOpen: true
      };
    }
    case HIDE_SIDEBAR: {
      return {
        sidebarOpen: false
      };
    }
    default:
      return state;
  }
};

export default uiReducer;
