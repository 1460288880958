import {
  SET_ACCESSTOKEN,
  REMOVE_ACCESSTOKEN,
  USER_LOGOUT
} from '../actions/actionTypes';

export const setAccessToken = token => dispatch => {
  localStorage.setItem('AT', token);
  dispatch({
    type: SET_ACCESSTOKEN,
    payload: {
      accessToken: token
    }
  });
};

export const logout = () => dispatch => {
  dispatch({ type: USER_LOGOUT });
  localStorage.removeItem('AT');
  dispatch({
    type: REMOVE_ACCESSTOKEN
  });
};
