import { combineReducers } from 'redux';

import getOTPReducer from './getOTPReducer';
import validateOTPReducer from './validateOTPReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import groupsReducer from './groupsReducer';
import selectedGroupReducer from './selectedGroupReducer';
import trackerReducer from './tracker/trackerReducer';
import uiReducer from './uiReducer';

import { USER_LOGOUT } from '../actions/actionTypes';

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  otp: combineReducers({
    get: getOTPReducer,
    validate: validateOTPReducer
  }),
  groups: combineReducers({
    list: groupsReducer,
    selected: selectedGroupReducer
  }),
  ui: uiReducer,
  tracker: trackerReducer
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
