import React from 'react';
import styles from '../activityView.module.scss';
import i18n from '../../../../utils/i18n';

import DoubleBar from './charts/doubleBar';

const Charts = ({ report, setGroupName }) => (
  <div>
    {/*<div className="mt50">*/}
    <div className={`${styles['container']} mt50`}>
      <div className={`${styles['col']}`}>
        <DoubleBar
          title={i18n.t('activity.avrMinutes')}
          property="avrActMin"
          report={report}
          setGroupName={setGroupName}
          colorScale={['#00d3ca']}
        />
      </div>

      <div className={`${styles['col']}`}>
        <DoubleBar
          title={i18n.t('activity.avrSteps')}
          property="avrSteps"
          report={report}
          setGroupName={setGroupName}
          colorScale={['#77c9ff']}
        />
      </div>

      <div className={`${styles['col']}`}>
        <DoubleBar
          title={i18n.t('activity.totalMinutes')}
          property="totalActMin"
          report={report}
          setGroupName={setGroupName}
          colorScale={['#00d3ca']}
        />
      </div>

      <div className={`${styles['col']}`}>
        <DoubleBar
          title={i18n.t('activity.totalSteps')}
          property="totalSteps"
          report={report}
          setGroupName={setGroupName}
          colorScale={['#77c9ff']}
        />
      </div>

      <div className={`${styles['col']}`}>
        <DoubleBar
          title={i18n.t('activity.sedentary')}
          property="avrSedHoursMin"
          report={report}
          setGroupName={setGroupName}
          colorScale={['#ff7100']}
          customLabels={true}
        />
      </div>
    </div>
  </div>
);

export default Charts;
