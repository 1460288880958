import {
  GET_OTP_REQUEST,
  GET_OTP_REQUEST_SUCCESS,
  GET_OTP_REQUEST_FAILURE
} from 'actions/actionTypes';

const getOTPReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_OTP_REQUEST:
      return {
        loading: true,
        success: false,
        error: false
      };
    case GET_OTP_REQUEST_SUCCESS:
      return {
        loading: false,
        success: true,
        error: false
      };
    case GET_OTP_REQUEST_FAILURE:
      return {
        loading: false,
        success: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
};

export default getOTPReducer;
