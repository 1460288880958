import React from 'react';
import Autocomplete from 'react-places-autocomplete';
import styles from './placesAutocomplete.module.scss';

const DotIndicator = () => {
  return (
    <div
      aria-hidden="true"
      className={`${styles['loading-indicator']}`}
    >
      <span className={`${styles['loading-dot-1']}`} />
      <span className={`${styles['loading-dot-2']}`} />
      <span className={`${styles['loading-dot-3']}`} />
    </div>
  );
};

export default function PlacesAutocomplete({
  onChange,
  onSelect,
  value,
  type,
  disabled,
  placeholder,
  isLoading = false
}) {
  return (
    <Autocomplete
      value={value}
      onChange={address => onChange(address, type)}
      onSelect={address => onSelect(address, type)}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="position-relative">
          <input
            {...getInputProps({
              placeholder: placeholder || 'Search Places...',
              className: `${styles['location-search-input']}`,
              disabled: disabled || false
            })}
          />
          {(loading || isLoading) && <DotIndicator />}
          {!!suggestions.length && (
            <div className={`${styles['autocomplete-dropdown-container']}`}>
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? `${styles['suggestion-item-active']}`
                  : `${styles['suggestion-item']}`;
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                    key={suggestion.placeId}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </Autocomplete>
  );
}
