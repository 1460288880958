import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import styles from './organizations.module.scss';

const AvgActivityDistributionChart = ({ data }) => {
  const { t } = useTranslation();
  const totalStudentsInClass = _.sumBy(data, 'count');

  return (
    <div className={`card ${styles['graph']}`}>
      <header className="card-header">
        <p className="card-header-title">
          {t('activity.physicalActivityDistribution')}
        </p>
      </header>
      <div className={`card-content ${styles['graph-content']}`}>
        <ResponsiveBar
          colors={b => '#00D3CA'}
          tooltip={({ data }) => <div>{data.count}</div>}
          margin={{
            top: 10,
            right: 20,
            bottom: 70,
            left: 65
          }}
          data={data}
          keys={['count']}
          axisLeft={{
            legend: t('activity.number_students'),
            legendPosition: 'middle',
            legendOffset: -40,
            format: e => Math.floor(e) === e && e
          }}
          axisBottom={{
            tickRotation: 40
          }}
          labelFormat={d => `${Math.round((d * 100) / totalStudentsInClass)}%`}
          indexBy="interval"
          className={`${styles['graph']}`}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 16
                }
              }
            },
            labels: {
              text: {
                fontSize: 16
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default AvgActivityDistributionChart;
