import React from 'react';
import { BoxLegendSvg } from '@nivo/legends';

export const BarLegend = ({ height, legends, width }) => (
  <React.Fragment>
    {legends.map(legend => (
      <BoxLegendSvg
        key={JSON.stringify(legend.data.map(({ id }) => id))}
        {...legend}
        containerHeight={height}
        containerWidth={width}
      />
    ))}
  </React.Fragment>
);

export const BarTotalsLayer = props => {
  const labelOffset = 7;
  const labelFontSize = 16;
  if (props.bars.length === 0) return null;

  const totals = {};
  const bandwidth = props.bars[0].width;
  props.bars.forEach(bar => {
    const indexValue = bar.data.indexValue;
    if (!(indexValue in totals)) {
      totals[indexValue] = 0;
    }
    if (!bar.data.hidden) {
      totals[indexValue] += bar.data.value;
    }
  });

  const labels = Object.keys(totals).map(indexValue => {
    const x = props.xScale(indexValue) + bandwidth / 2;
    const y = props.yScale(totals[indexValue]) - labelOffset;
    return (
      <text
        key={'total.' + indexValue}
        x={x}
        y={y}
        textAnchor={'middle'}
        fontSize={labelFontSize}
      >
        {totals[indexValue]}
      </text>
    );
  });
  return <>{labels}</>;
};
