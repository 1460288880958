import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  getGroupsNames,
  getTrackerReport,
  clearReport
} from '../../../actions/trackerActions';

import ActivityView from '../../presentation/activity/activityView';

class ActivityContainer extends Component {
  constructor() {
    super();

    this.state = {
      expLoader: false,
      tableLen: null
    };
  }

  componentDidMount() {
    this.setTableLen(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.trackersReport) !==
      JSON.stringify(prevProps.trackersReport)
    ) {
      this.setTableLen(this.props);
    }
  }

  componentWillUnmount() {
    this.props.clearReport();
  }

  setTableLen = ({ trackersReport }) => {
    this.setState({
      tableLen: trackersReport ? trackersReport.length : 0
    });
  };

  expStart = callback => {
    this.toggleLoader();

    const table = document.getElementById('accordionTable');
    const [, tbody] = table.childNodes;
    const tr = [...tbody.childNodes];

    const openSubTable = tr.find(el => /not-collapsed/.test(el.className));

    if (openSubTable) {
      const subBodyTrs =
        openSubTable.nextSibling.childNodes[0].childNodes[0].childNodes[0]
          .childNodes[0].childNodes;

      this.setState({
        tableLen: this.state.tableLen + subBodyTrs.length
      });
    }

    callback();
  };

  toggleLoader = () => this.setState({ expLoader: !this.state.expLoader });

  render() {
    const {
      loading,
      report,
      setGroupName,
      trackersReport,
      period
    } = this.props;

    const { tableLen, expLoader } = this.state;

    return (
      <ActivityView
        report={report}
        loading={loading}
        setGroupName={setGroupName}
        trackersReport={trackersReport}
        tableLen={tableLen}
        period={period}
        expLoader={expLoader}
        expStart={this.expStart}
        toggleLoader={this.toggleLoader}
      />
    );
  }
}

const mapStateToProps = state => {
  const {
    filtReport,
    report,
    trackersReport,
    filtTrackersReport
  } = state.tracker;

  return {
    report: filtReport || report,
    loading: state.tracker.loading,
    setGroupName: state.tracker.setGroupName,
    trackersReport: filtTrackersReport || trackersReport,
    period: state.tracker.period
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getGroupsNames: () => {
      dispatch(getGroupsNames());
    },
    getTrackerReport: groupName => {
      dispatch(getTrackerReport(groupName));
    },
    clearReport: () => {
      dispatch(clearReport());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityContainer);
