import React from 'react';
import ReactDOM from 'react-dom';
// import 'babel-polyfill'; /* IE */

import { I18nextProvider } from 'react-i18next';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import store from './store/store';

import i18n from './utils/i18n';
import * as serviceWorker from './serviceWorker';

import App from './App';

import './styles/theme.css';

ReactGA.initialize('UA-108048631-7', {
  debug: process.env.NODE_ENV === 'development'
});
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
