import { SET_ACCESSTOKEN, REMOVE_ACCESSTOKEN } from 'actions/actionTypes';

const initialState = {
  accessToken: localStorage.getItem('AT') || null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCESSTOKEN:
      return { accessToken: action.payload.accessToken };
    case REMOVE_ACCESSTOKEN:
      return { accessToken: null };
    default:
      return state;
  }
};

export default authReducer;
