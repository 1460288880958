import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  getOrganizations,
  selectOrganization,
  selectOrganizationGroup,
  selectOrganizationGroupPeriod,
  getSummaryStatistic
} from '../../../actions/trackerActions';

class OrganizationContainer extends Component {
  componentDidMount() {
    this.props.getOrganizations();
    this.props.getSummaryStatistic();
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { ...this.props });
      }
      return child;
    });

    return <div>{childrenWithProps}</div>;
  }
}

const mapStateToProps = state => ({
  organizations: state.tracker.organizations.data,
  isOrganizationsLoading: state.tracker.organizations.loading,
  selectedOrganization: state.tracker.selectedOrganization,
  organizationGroups: state.tracker.organizationGroups.data,
  isOrganizationGroupsLoading: state.tracker.organizationGroups.loading,
  selectedOrganizationGroup: state.tracker.selectedOrganizationGroup,
  organizationGroupPeriods: state.tracker.organizationGroupPeriods.data,
  isOrganizationGroupPeriodsLoading:
    state.tracker.organizationGroupPeriods.loading,
  selectedOrganizationGroupPeriod:
    state.tracker.selectedOrganizationGroupPeriod,
  isOrganizationGroupPeriodTrackerDetailsLoading:
    state.tracker.organizationGroupPeriodTrackerDetails.loading,
  avgActivityPerDay:
    state.tracker.organizationGroupPeriodTrackerDetails.avgActivityPerDay,
  avgStepsPerDay:
    state.tracker.organizationGroupPeriodTrackerDetails.avgStepsPerDay,
  totalStepsPerDay:
    state.tracker.organizationGroupPeriodTrackerDetails.totalStepsPerDay,
  trackerReport:
    state.tracker.organizationGroupPeriodTrackerDetails.trackerReport,
  trackerReportWithoutStepsFilter:
    state.tracker.organizationGroupPeriodTrackerDetails
      .trackerReportWithoutStepsFilter,
  avgStepsPerHour:
    state.tracker.organizationGroupPeriodTrackerDetails.avgStepsPerHour,
  avgActivityPerHour:
    state.tracker.organizationGroupPeriodTrackerDetails.avgActivityPerHour,
  avgActivitySpread:
    state.tracker.organizationGroupPeriodTrackerDetails.avgActivitySpread,
  avgStepSpread:
    state.tracker.organizationGroupPeriodTrackerDetails.avgStepSpread,
  avgSedPerDay:
    state.tracker.organizationGroupPeriodTrackerDetails.avgSedPerDay,
  avgActivityGenderDay:
    state.tracker.organizationGroupPeriodTrackerDetails.avgActivityGenderDay,
  avgStepsGenderDay:
    state.tracker.organizationGroupPeriodTrackerDetails.avgStepsGenderDay,
  avgLocationActivityDay:
    state.tracker.organizationGroupPeriodTrackerDetails.avgLocationActivityDay,
  summaryStatistic: state.tracker.summaryStatistic.data
});

const mapDispatchToProps = dispatch => {
  return {
    getOrganizations: () => {
      dispatch(getOrganizations());
    },
    selectOrganization: organization => {
      dispatch(selectOrganization(organization));
    },
    selectOrganizationGroup: organizationGroup => {
      dispatch(selectOrganizationGroup(organizationGroup));
    },
    selectOrganizationGroupPeriod: (
      organizationGroupPeriod,
      willLoadDetails
    ) => {
      dispatch(
        selectOrganizationGroupPeriod(organizationGroupPeriod, willLoadDetails)
      );
    },
    getSummaryStatistic: () => {
      dispatch(getSummaryStatistic());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationContainer);
