import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './organizations.module.scss';
import stylesAct from '../activity/activityView.module.scss';
import Select from 'react-select';
import moment from 'moment';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { withScriptjs } from 'react-google-maps';
import { withTranslation } from 'react-i18next';

import Map from './StepMap';
import PlacesAutocomplete from '../../general/placesAutocomplete/placesAutocomplete';
import {
  getGroups,
  getGroupPeriods,
  getTrackerPeriodDetails,
  selectPredefinedFields
} from '../../../actions/trackerActions';

// The logic for selecting CLASS 2 should be moved probably in redux as common actions
const places = [
  { latitude: 25.8103146, longitude: -80.1751609 },
  { latitude: 27.9947147, longitude: -82.5943645 }
];
const isCommonWayOptions = [
  {
    label: 'Samarbeta',
    value: true
  },
  {
    label: 'Tävla',
    value: false
  }
];

class ChooseOrganizationMap extends Component {
  state = {
    originRaw: localStorage.getItem('originRaw') || '',
    destinationRaw: localStorage.getItem('destinationRaw') || '',
    originLatLng: JSON.parse(localStorage.getItem('origin')),
    destinationLatLng: JSON.parse(localStorage.getItem('destination')),
    organizations2: {
      selected: JSON.parse(localStorage.getItem('class2_organization'))
    },
    organizationGroups2: {
      data: [],
      loading: false,
      selected: JSON.parse(localStorage.getItem('class2_group'))
    },
    organizationGroupPeriods2: {
      data: [],
      loading: false,
      selected: JSON.parse(localStorage.getItem('class2_period'))
    },
    totalStepsPerDay2: {
      data: undefined,
      loading: false
    },
    isCommonWay: {
      label: 'Samarbeta',
      value: true
    }
  };

  componentWillUnmount() {
    this.props.selectOrganization('');
  }

  componentDidMount() {
    const {
      organizations2,
      organizationGroups2,
      organizationGroupPeriods2
    } = this.state;

    if (localStorage.getItem('class1_period')) {
      this.props.selectPredefinedFields();
      this.props.getGroups(
        JSON.parse(localStorage.getItem('class1_organization'))
      );
      this.props.getGroupPeriods(
        JSON.parse(localStorage.getItem('class1_group'))
      );
    }
    if (organizationGroupPeriods2.selected) {
      this.props.getTrackerPeriodDetails(
        organizationGroupPeriods2.selected.value,
        organizationGroupPeriods2.selected.startTimestamp,
        organizationGroupPeriods2.selected.endTimestamp,
        {
          name: 'totalStepsPerDay2',
          func: this.handleResponse
        }
      );
      this.props.getGroups(organizations2.selected, {
        name: 'organizationGroups2',
        func: this.handleResponse
      });
      this.props.getGroupPeriods(organizationGroups2.selected, {
        name: 'organizationGroupPeriods2',
        func: this.handleResponse
      });
    }
  }

  updateLocalStorage = cls => {
    if (cls === 2) {
      localStorage.setItem(
        'class2_organization',
        JSON.stringify(this.state.organizations2.selected)
      );
      localStorage.setItem(
        'class2_group',
        JSON.stringify(this.state.organizationGroups2.selected)
      );
      localStorage.setItem(
        'class2_period',
        JSON.stringify(this.state.organizationGroupPeriods2.selected)
      );
    } else {
      localStorage.setItem(
        'class1_organization',
        JSON.stringify(this.props.selectedOrganization)
      );
      localStorage.setItem(
        'class1_group',
        JSON.stringify(this.props.selectedOrganizationGroup)
      );
      localStorage.setItem(
        'class1_period',
        JSON.stringify(this.props.selectedOrganizationGroupPeriod)
      );
    }
    localStorage.setItem('origin', JSON.stringify(this.state.originLatLng));
    localStorage.setItem('originRaw', this.state.originRaw);
    localStorage.setItem(
      'destination',
      JSON.stringify(this.state.destinationLatLng)
    );
    localStorage.setItem('destinationRaw', this.state.destinationRaw);
  };

  handleChangeAddress = (address, type) => {
    if (type === 'origin') this.setState({ originRaw: address });
    if (type === 'destination') this.setState({ destinationRaw: address });
  };

  handleSelectAddress = (address, type) => {
    this.handleChangeAddress(address, type);

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        if (type === 'origin') this.setState({ originLatLng: latLng });
        if (type === 'destination')
          this.setState({ destinationLatLng: latLng });
      })
      .catch(error => console.error('Error', error));
  };

  handleSelect = (selected, name) => {
    const organizations = { ...this.state.organizations2 };
    const organizationGroups = { ...this.state.organizationGroups2 };
    const organizationGroupPeriods = {
      ...this.state.organizationGroupPeriods2
    };
    const totalStepsPerDay = { ...this.state.totalStepsPerDay2 };

    switch (name) {
      case 'organizations2':
        this.props.getGroups(selected, {
          name: 'organizationGroups2',
          func: this.handleResponse
        });

        organizations.selected = selected;
        organizationGroups.selected = undefined;
        organizationGroups.loading = true;
        organizationGroupPeriods.selected = undefined;
        organizationGroupPeriods.data = [];
        totalStepsPerDay.data = undefined;
        this.setState({
          organizations2: organizations,
          organizationGroups2: organizationGroups,
          organizationGroupPeriods2: organizationGroupPeriods,
          totalStepsPerDay2: totalStepsPerDay
        });
        break;
      case 'organizationGroups2':
        this.props.getGroupPeriods(selected, {
          name: 'organizationGroupPeriods2',
          func: this.handleResponse
        });

        organizationGroups.selected = selected;
        organizationGroupPeriods.selected = undefined;
        organizationGroupPeriods.loading = true;
        totalStepsPerDay.data = undefined;
        this.setState({
          organizationGroups2: organizationGroups,
          organizationGroupPeriods2: organizationGroupPeriods,
          totalStepsPerDay2: totalStepsPerDay
        });
        break;
      case 'organizationGroupPeriods2':
        this.props.getTrackerPeriodDetails(
          selected.value,
          selected.startTimestamp,
          selected.endTimestamp,
          {
            name: 'totalStepsPerDay2',
            func: this.handleResponse
          }
        );

        organizationGroupPeriods.selected = selected;
        totalStepsPerDay.loading = true;
        this.setState({
          organizationGroupPeriods2: organizationGroupPeriods,
          totalStepsPerDay2: totalStepsPerDay
        });
        break;

      default:
        break;
    }
  };

  handleResponse = (response, name) => {
    const data = { ...this.state[name] };
    data.data = response;
    data.loading = false;
    this.setState({ [name]: data });
  };

  render() {
    const {
      organizations,
      isOrganizationsLoading,
      selectOrganization,
      selectedOrganization,
      organizationGroups,
      isOrganizationGroupsLoading,
      selectedOrganizationGroup,
      selectOrganizationGroup,
      organizationGroupPeriods,
      isOrganizationGroupPeriodsLoading,
      selectedOrganizationGroupPeriod,
      selectOrganizationGroupPeriod,
      isOrganizationGroupPeriodTrackerDetailsLoading,
      totalStepsPerDay,
      t
    } = this.props;
    const {
      organizations2,
      organizationGroups2,
      organizationGroupPeriods2,
      totalStepsPerDay2
    } = this.state;

    const organizationOptions = organizations.map(o => ({
      label: o.name,
      value: o.organizationId
    }));

    const groupOptions = organizationGroups.map(o => ({
      label: o.name,
      value: o.groupId
    }));

    const formatTs = ts => moment(ts).format('YYYY-MM-DD');
    const weekFormatTs = ts => moment(ts).format('W');

    const periodOptions = organizationGroupPeriods
      .filter(el => !el.deleted)
      .map(o => ({
        label: `v${weekFormatTs(o.startTimestamp)}, ${formatTs(
          o.startTimestamp
        )} - ${formatTs(o.endTimestamp)}`,
        value: o.periodId,
        ...o
      }));

    const periodOptions2 = organizationGroupPeriods2.data
      .filter(el => !el.deleted)
      .map(o => ({
        label: `v${weekFormatTs(o.startTimestamp)}, ${formatTs(
          o.startTimestamp
        )} - ${formatTs(o.endTimestamp)}`,
        value: o.periodId,
        ...o
      }));
    return (
      <div>
        <div
          className={`${styles['card-wide']} ${styles['list-style-default']}`}
        >
          <h1 className={`${stylesAct['table-title']} mt20`}>
            <b>{t('map.stepmap')}</b>
          </h1>
          <p className="mb20">{t('map.text1')}</p>
          <ul>
            <li className="mb20">{t('map.text2')}</li>
            <li className="mb20">{t('map.text3')}</li>
            <li className="mb20">{t('map.text4')}</li>
          </ul>
          <p>{t('map.text5')}</p>
        </div>
        <div className={`${styles['card-wide']} card mt30`}>
          <div className="card-content d-flex pb-0">
            <form className="w-100 mr-3">
              <div className="field">
                <label htmlFor="#groupName" className="label mb20">
                  {t('setGroup.class')} 1
                </label>
              </div>
              <div className="field">
                <Select
                  isLoading={isOrganizationsLoading}
                  value={selectedOrganization || ''}
                  onChange={selectedOrganization =>
                    selectOrganization(selectedOrganization)
                  }
                  options={organizationOptions}
                  isSearchable
                  isClearable
                  placeholder={t('setGroup.school')}
                  noOptionsMessage={({ inputValue }) => 'Inga skolor hittade'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#94e9e6',
                      primary: '#00d3ca'
                    }
                  })}
                />
              </div>
              <div className="field">
                <Select
                  isLoading={isOrganizationGroupsLoading}
                  value={selectedOrganizationGroup || ''}
                  onChange={selectedOrganizationGroup =>
                    selectOrganizationGroup(selectedOrganizationGroup)
                  }
                  options={groupOptions}
                  isSearchable
                  placeholder={t('setGroup.class')}
                  noOptionsMessage={({ inputValue }) => 'Inga klasser hittade'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#94e9e6',
                      primary: '#00d3ca'
                    }
                  })}
                />
              </div>
              <div className="field">
                <Select
                  isLoading={isOrganizationGroupPeriodsLoading}
                  value={selectedOrganizationGroupPeriod || ''}
                  onChange={selectedOrganizationGroupPeriod =>
                    selectOrganizationGroupPeriod(
                      selectedOrganizationGroupPeriod
                    )
                  }
                  options={periodOptions}
                  isSearchable
                  placeholder={t('setGroup.measurement_period')}
                  noOptionsMessage={({ inputValue }) => 'Inga perioder hittade'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#94e9e6',
                      primary: '#00d3ca'
                    }
                  })}
                />
              </div>
            </form>
            <form className="w-100">
              <div className="field">
                <label htmlFor="#groupName" className="label mb20">
                  {t('setGroup.class')} 2
                </label>
              </div>
              <div className="field">
                <Select
                  isLoading={isOrganizationsLoading}
                  value={organizations2.selected || ''}
                  onChange={selectedOrganization =>
                    this.handleSelect(selectedOrganization, 'organizations2')
                  }
                  options={organizationOptions}
                  isSearchable
                  isClearable
                  placeholder={t('setGroup.school')}
                  noOptionsMessage={({ inputValue }) => 'Inga skolor hittade'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#94e9e6',
                      primary: '#00d3ca'
                    }
                  })}
                />
              </div>
              <div className="field">
                <Select
                  isLoading={organizationGroups2.loading}
                  value={organizationGroups2.selected || ''}
                  onChange={selectedOrganizationGroup =>
                    this.handleSelect(
                      selectedOrganizationGroup,
                      'organizationGroups2'
                    )
                  }
                  options={organizationGroups2.data.map(o => ({
                    label: o.name,
                    value: o.groupId
                  }))}
                  isSearchable
                  placeholder={t('setGroup.class')}
                  noOptionsMessage={({ inputValue }) => 'Inga klasser hittade'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#94e9e6',
                      primary: '#00d3ca'
                    }
                  })}
                />
              </div>
              <div className="field">
                <Select
                  isLoading={organizationGroupPeriods2.loading}
                  value={organizationGroupPeriods2.selected || ''}
                  onChange={selectedOrganizationGroupPeriod =>
                    this.handleSelect(
                      selectedOrganizationGroupPeriod,
                      'organizationGroupPeriods2'
                    )
                  }
                  options={periodOptions2}
                  isSearchable
                  placeholder={t('setGroup.measurement_period')}
                  noOptionsMessage={({ inputValue }) => 'Inga perioder hittade'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#94e9e6',
                      primary: '#00d3ca'
                    }
                  })}
                />
              </div>
            </form>
          </div>
          <div className="card-content">
            <form>
              <div className="field">
                <PlacesAutocomplete
                  value={this.state.originRaw}
                  onChange={this.handleChangeAddress}
                  onSelect={this.handleSelectAddress}
                  type="origin"
                  disabled={!totalStepsPerDay && !totalStepsPerDay2.data}
                  placeholder="Startpunkt"
                  isLoading={
                    isOrganizationGroupPeriodTrackerDetailsLoading ||
                    totalStepsPerDay2.loading
                  }
                />
              </div>
              <div className="field">
                <PlacesAutocomplete
                  value={this.state.destinationRaw}
                  onChange={this.handleChangeAddress}
                  onSelect={this.handleSelectAddress}
                  type="destination"
                  disabled={!this.state.originLatLng}
                  placeholder="Destination"
                />
              </div>
              <div className="field">
                <Select
                  value={this.state.isCommonWay}
                  onChange={val => this.setState({ isCommonWay: val })}
                  options={isCommonWayOptions}
                  isSearchable
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#94e9e6',
                      primary: '#00d3ca'
                    }
                  })}
                />
              </div>
            </form>
          </div>
        </div>
        {this.state.destinationLatLng && (
          <Map
            markers={places}
            defaultCenter={{ lat: 60.1282, lng: 18.6435 }}
            defaultZoom={11}
            containerElement={
              <div
                style={{
                  height: '80vh',
                  marginTop: '20px',
                  width: '769px',
                  maxWidth: '100%'
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
            origin={this.state.originLatLng}
            destination={this.state.destinationLatLng}
            totalStepsPerDay={totalStepsPerDay}
            totalStepsPerDay2={totalStepsPerDay2.data}
            isCommonWay={this.state.isCommonWay.value}
            updateLocalStorage={this.updateLocalStorage}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getGroups: (organization, callback) => {
      dispatch(getGroups(organization, callback));
    },
    getGroupPeriods: (group, callback) => {
      dispatch(getGroupPeriods(group, callback));
    },
    getTrackerPeriodDetails: (id, from, to, callback) => {
      dispatch(
        getTrackerPeriodDetails(
          id,
          moment(from).utc(),
          moment(to).utc(),
          callback
        )
      );
    },
    selectPredefinedFields: () => {
      dispatch(selectPredefinedFields());
    }
  };
};

export default withTranslation()(
  withScriptjs(connect(null, mapDispatchToProps)(ChooseOrganizationMap))
);
