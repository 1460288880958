import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getUser } from '../../../actions/userActions';
import { toggleSidebar } from '../../../actions/uiActions';

import Main from '../../../components/presentation/main/main';

class MainContainer extends Component {
  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    this.props.getUser();
  };

  render() {
    return <Main {...this.props} />;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleSidebar: show => {
      dispatch(toggleSidebar(show));
    },
    getUser: onComplete => {
      dispatch(getUser(onComplete));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(MainContainer);
