import {
  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILURE
} from 'actions/actionTypes';

const initialState = {
  data: [],
  error: false,
  errorMessage: null,
  loading: false
};

const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUPS_REQUEST: {
      return {
        ...state,
        error: false,
        errorMessage: initialState.errorMessage,
        loading: true
      };
    }
    case GET_GROUPS_SUCCESS: {
      return {
        ...state,
        data: action.payload.groups,
        error: false,
        loading: false
      };
    }
    case GET_GROUPS_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage
      };
    }
    default:
      return state;
  }
};

export default groupsReducer;
