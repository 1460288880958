import moment from 'moment';

export const toggleTableArrow = (e, { id, colStyle, notColStyle }) => {
  const table = document.getElementById(id);
  const trs = table.childNodes[1].childNodes;

  const curTr = [...trs].find(tr => /not-collapsed/.test(tr.className));
  const clickedTr = e.target.tagName === 'TR' ? e.target : e.target.parentNode;

  if (curTr && curTr === clickedTr) {
    curTr.className = notColStyle;
    return;
  }

  if (curTr) {
    curTr.className = notColStyle;
  }

  // Update current tr className
  clickedTr.className = colStyle;
};

export const getDaysAgo = days => {
  const formatDate = date => {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const today = new Date();
  const toDate = formatDate(today);
  today.setDate(today.getDate() - days);
  const fromDate = formatDate(today);

  return { fromDate, toDate };
};

export const setGoogleExtension = google => {
  google.maps.Polyline.prototype.GetPointAtDistance = function(metres) {
    // some awkward special cases

    if (metres === 0) return this.getPath().getAt(0);
    if (metres < 0) return null;
    if (this.getPath().getLength() < 2) return null;
    var dist = 0;
    var olddist = 0;
    for (var i = 1; i < this.getPath().getLength() && dist < metres; i++) {
      olddist = dist;
      dist += google.maps.geometry.spherical.computeDistanceBetween(
        this.getPath().getAt(i),
        this.getPath().getAt(i - 1)
      );
    }
    if (dist < metres) {
      return null;
    }
    var p1 = this.getPath().getAt(i - 2);
    var p2 = this.getPath().getAt(i - 1);
    var m = (metres - olddist) / (dist - olddist);
    return new google.maps.LatLng(
      p1.lat() + (p2.lat() - p1.lat()) * m,
      p1.lng() + (p2.lng() - p1.lng()) * m
    );
  };
};

export const minToHours = min => {
  return moment
    .utc()
    .startOf('day')
    .add(min, 'minutes')
    .format('H:mm');
};
