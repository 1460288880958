import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './../resources/locales/en.json';
import sv from './../resources/locales/sv.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    load: 'languageOnly',
    resources: {
      en: { translation: en },
      sv: { translation: sv }
    }
  });

export default i18n;
