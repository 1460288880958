import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './organizations.module.scss';

const AvgSedPerDayChart = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className={`card ${styles['graph']}`}>
      <header className="card-header">
        <p className="card-header-title">{t('activity.sedentary_time_2')}</p>
      </header>
      <div className={`card-content ${styles['graph-content']}`}>
        <ResponsiveBar
          colors={b => '#e06666'}
          tooltip={({ data }) => <div>{data.sed}%</div>}
          padding={0.4}
          margin={{
            top: 10,
            right: 20,
            bottom: 70,
            left: 65
          }}
          data={data}
          keys={['sed']}
          axisLeft={{
            legend: 'per',
            legendPosition: 'middle',
            legendOffset: -60,
            format: e => `${e}%`
          }}
          axisBottom={{
            tickRotation: 40
          }}
          labelFormat={d => `${d}%`}
          indexBy="day"
          className={`${styles['graph']}`}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 16
                }
              }
            },
            labels: {
              text: {
                fontSize: 16
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default AvgSedPerDayChart;
