import { ResponsiveBar } from '@nivo/bar';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { BarLegend, BarTotalsLayer } from '../../../helpFunctions/graphLayers';
import styles from './organizations.module.scss';

const AvgStepsPerDayChart = ({ data }) => {
  const { t } = useTranslation();
  const [selectedFilters, setSelectedFilters] = useState(new Set());
  const [stateData, setStateData] = useState({
    keys: ['Skoltid', 'Fritid'],
    filteredData: {}
  });

  useEffect(() => {
    setStateData(stateData => ({
      ...stateData,
      filteredData: data
    }));

    setSelectedFilters(s => {
      s.clear();
      return s;
    });
  }, [data]);

  return (
    <div className={`card ${styles['graph']}`}>
      <header className="card-header">
        <p className="card-header-title">{t('activity.stepsAvrGroup')}</p>
      </header>
      <div className={`card-content ${styles['graph-content']}`}>
        <ResponsiveBar
          colors={b => {
            if (b.id === 'Fritid') {
              return '#0D77AF';
            }

            return '#2AA4E1';
          }}
          margin={{
            top: 20,
            right: 110,
            bottom: 70,
            left: 65
          }}
          axisLeft={{
            legend: t('activity.steps_2'),
            legendPosition: 'middle',
            legendOffset: -60
          }}
          axisBottom={{
            tickRotation: 40
          }}
          layers={['grid', 'axes', 'bars', BarLegend, BarTotalsLayer]}
          legends={[
            {
              symbolShape: e => {
                const { x, y, size, fill, borderWidth, borderColor } = e;
                return (
                  <rect
                    x={x}
                    y={y}
                    width={size}
                    height={size}
                    fill={selectedFilters.has(e.id) ? '#ffffff' : fill}
                    strokeWidth={borderWidth}
                    stroke={borderColor}
                  />
                );
              },
              dataFrom: 'keys',
              data: stateData.keys.map(id => ({
                color: id === 'Fritid' ? '#0D77AF' : '#2AA4E1',
                id,
                label:
                  id === 'Skoltid' && !selectedFilters.has(id)
                    ? t('activity.school_time')
                    : id === 'Fritid' && !selectedFilters.has(id)
                    ? t('activity.leisure_time')
                    : ''
              })),
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          enableLabel={false}
          indexBy="day"
          data={stateData.filteredData}
          keys={stateData.keys}
          className={`${styles['graph']}`}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 16
                }
              }
            },
            labels: {
              text: {
                fontSize: 16
              }
            }
          }}
        />
      </div>
      <div className={`control ${styles['center']}`}>
        {stateData.keys.map(k => (
          <label className={`checkbox ${styles['day-control']}`} key={k}>
            <input
              type="checkbox"
              id={k}
              className={`${styles['day-control']}`}
              checked={!selectedFilters.has(k)}
              readOnly
              onClick={event => {
                const target = event.target;
                if (target.type === 'checkbox') {
                  if (target.checked) {
                    selectedFilters.delete(target.id);
                    setSelectedFilters(selectedFilters);
                  } else {
                    setSelectedFilters(selectedFilters.add(target.id));
                  }
                }

                const { keys } = stateData;

                const filteredFilters = _.without(keys, ...selectedFilters);

                const filteredData = data.map(s =>
                  _(s)
                    .pick(['day', ...filteredFilters])
                    .value()
                );

                setStateData({
                  ...stateData,
                  filteredData
                });
              }}
            />
            {k === 'Skoltid'
              ? t('activity.school_time')
              : t('activity.leisure_time')}
          </label>
        ))}
      </div>
    </div>
  );
};

export default AvgStepsPerDayChart;
