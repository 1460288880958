import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../utils/i18n';

class LoginOTPInputForm extends Component {
  constructor(props) {
    super(props);
    this.state = { code: '' };
  }

  onCodeChange = e => {
    this.setState({ code: e.target.value });
  };

  onFormSubmit = e => {
    e.preventDefault();
    this.props.onSubmit(this.state.code);
  };

  render() {
    const { error } = this.props;
    return (
      <Fragment>
        {/*{!error && (*/}
        {/*  <div className="notification is-success">*/}
        {/*    <p>{i18n.t('login.otpSentSuccess')}</p>*/}
        {/*  </div>*/}
        {/*)}*/}
        {error && (
          <div className="notification is-danger">
            <p>{i18n.t('error.default')}</p>
          </div>
        )}
        <form onSubmit={this.onFormSubmit}>
          <div className="field">
            <label className="label mb20">{i18n.t('login.enterOtp')}</label>
            <div className="control has-icons-left mb30">
              <input
                onChange={this.onCodeChange}
                type="tel"
                className="input"
                placeholder={i18n.t('login.otpPlaceholder')}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-keyboard" />
              </span>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button className="button is-fullwidth is-primary">
                {i18n.t('login.validateButton')}
              </button>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

LoginOTPInputForm.propTypes = {
  code: PropTypes.string
};

export default LoginOTPInputForm;
