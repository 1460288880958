import React from 'react';

// import i18n from '../../../utils/i18n';

// import styles from './dashboardView.module.scss';
import SetGroupContainer from '../../containers/setGroup/setGroupContainer';

const DashboardView = () => (
  <div>
    <SetGroupContainer />
  </div>
);

export default DashboardView;
