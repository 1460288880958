import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import MainContainer from '../../containers/main/mainContainer';
import DashboardView from '../../presentation/dashboard/dashboardView';
import ActivityContainer from '../activity/activityContainer';

import i18n from '../../../utils/i18n';

class DashboardContainer extends Component {
  render() {
    const { setGroupName } = this.props;

    return (
      <Fragment>
        <MainContainer title={i18n.t('main.title')}>
          <DashboardView />
        </MainContainer>

        {setGroupName && <ActivityContainer />}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    setGroupName: state.tracker.setGroupName
  };
};

export default connect(
  mapStateToProps,
  null
)(DashboardContainer);
