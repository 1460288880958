import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import styles from './organizations.module.scss';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const AvgStepsPerHourChart = ({ data }) => {
  const { t } = useTranslation();
  const [selectedDays, setSelectedDays] = useState(new Set());
  const [stateData, setStateData] = useState({
    keys: [],
    dataDayHours: {},
    filteredData: {}
  });

  useEffect(() => {
    const dataDayHours = _(data)
      .filter(d =>
        _.includes(
          [
            '08-09',
            '09-10',
            '10-11',
            '11-12',
            '12-13',
            '13-14',
            '14-15',
            '15-16'
          ],
          d.hour
        )
      )
      .sortBy('hour')
      .value();
    const keys = _(dataDayHours)
      .map(v =>
        _(v)
          .keys()
          .filter(k => k !== 'hour')
          .uniq()
          .value()
      )
      .flatten()
      .uniq()
      .sort()
      .value();

    setStateData(stateData => ({
      ...stateData,
      dataDayHours,
      filteredData: dataDayHours,
      keys
    }));

    setSelectedDays(s => {
      s.clear();
      return s;
    });
  }, [data]);

  return (
    <div className={`card ${styles['graph']}`}>
      <header className="card-header">
        <p className="card-header-title">{t('activity.stepsDay')}</p>
      </header>
      <div className={`card-content ${styles['graph-content']}`}>
        <ResponsiveBar
          colors={[
            '#b83ba1',
            '#782669',
            '#2b046f',
            '#2aa4e1',
            '#25c6bf',
            '#979797'
          ]}
          margin={{
            top: 10,
            right: 110,
            bottom: 70,
            left: 65
          }}
          axisBottom={{
            legendOffset: -12,
            tickRotation: 40
          }}
          axisLeft={{
            legend: t('activity.steps_2'),
            legendPosition: 'middle',
            legendOffset: -60
          }}
          legends={[
            {
              symbolShape: e => {
                const { x, y, size, fill, borderWidth, borderColor } = e;
                return (
                  <rect
                    x={x}
                    y={y}
                    width={size}
                    height={size}
                    fill={selectedDays.has(e.id) ? '#ffffff' : fill}
                    strokeWidth={borderWidth}
                    stroke={borderColor}
                  />
                );
              },
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          enableLabel={false}
          indexBy="hour"
          data={stateData.filteredData}
          keys={stateData.keys}
          className={`${styles['graph']}`}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 16
                }
              }
            },
            labels: {
              text: {
                fontSize: 16
              }
            }
          }}
        />
      </div>
      <div className={`control ${styles['center']}`}>
        {stateData.keys.map(k => (
          <label className={`checkbox ${styles['day-control']}`} key={k}>
            <input
              type="checkbox"
              id={k}
              className={`${styles['day-control']}`}
              checked={!selectedDays.has(k)}
              readOnly
              onClick={event => {
                const target = event.target;
                if (target.type === 'checkbox') {
                  if (target.checked) {
                    selectedDays.delete(target.id);
                    setSelectedDays(selectedDays);
                  } else {
                    setSelectedDays(selectedDays.add(target.id));
                  }
                }

                const { dataDayHours, keys } = stateData;

                const filteredDays = _.without(keys, ...selectedDays);

                const filteredData = dataDayHours.map(s =>
                  _(s)
                    .pick(['hour', ...filteredDays])
                    .value()
                );

                setStateData({
                  ...stateData,
                  filteredData
                });
              }}
            />
            {k}
          </label>
        ))}
      </div>
    </div>
  );
};

export default AvgStepsPerHourChart;
