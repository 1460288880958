import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE
} from '../actions/actionTypes';

import HTTPService from '../utils/httpService';

export const getUser = onComplete => (dispatch, getState) => {
  const { accessToken } = getState().auth;

  if (!Boolean(accessToken)) {
    dispatch({
      type: GET_USER_FAILURE,
      payload: {
        errorMessage: 'Missing accessToken'
      }
    });
    return;
  }

  dispatch({
    type: GET_USER_REQUEST
  });

  const httpClient = new HTTPService().client;

  httpClient
    .get('user')
    .then(response => {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: {
          user: response.data
        }
      });
      if (onComplete) onComplete();
    })
    .catch(error => {
      dispatch({
        type: GET_USER_FAILURE,
        payload: {
          errorMessage: `Failed with ${handleGetUserError(error)}`
        }
      });
    });
};

const handleGetUserError = error => {
  if (Boolean(error.response)) {
    return error.response.status;
  }

  return 'Unknown error';
};
