import React, { Component } from 'react';
import LoginPhoneNumberForm from './loginPhoneNumberForm';
import LoginOTPInputForm from './loginOTPInputForm';
import i18n from '../../../utils/i18n';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      countryCode: '',
      phoneNumber: ''
    };
  }

  onPhoneNumberSubmit = (countryCode, phoneNumber) => {
    this.setState({ countryCode, phoneNumber });
    this.props.onPhoneNumberSubmit(countryCode, phoneNumber);
  };

  onOTPSubmit = code => {
    this.setState({ code });
    this.props.onOTPSubmit(
      code,
      this.state.countryCode,
      this.state.phoneNumber
    );
  };

  render() {
    const {
      getOTPLoading,
      getOTPSuccess,
      getOTPError,
      validateOTPLoading,
      validateOTPSuccess,
      validateOTPError
    } = this.props;

    return (
      <div className="hero is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-fullwidth-mobile is-half-tablet is-one-third-desktop">
                <div className="card">
                  <div className="card-content">
                    <h1 className="title is-title-1 pt40">
                      {i18n.t('app.title')}
                    </h1>
                    <h1 className="subtitle pb20">{i18n.t('login.text')}</h1>
                    {!getOTPSuccess && (
                      <LoginPhoneNumberForm
                        onSubmit={this.onPhoneNumberSubmit}
                        loading={getOTPLoading}
                        success={getOTPSuccess}
                        error={getOTPError}
                      />
                    )}
                    {getOTPSuccess && (
                      <LoginOTPInputForm
                        onSubmit={this.onOTPSubmit}
                        loading={validateOTPLoading}
                        success={validateOTPSuccess}
                        error={validateOTPError}
                      />
                    )}
                  </div>
                </div>
                <div className="control is-medium mt20">
                  <a href="https://www.scriin.org">
                    <p className="subtitle has-text-centered">
                      {i18n.t('app.website')}
                    </p>
                  </a>
                </div>
                <div className="control is-medium mt70">
                  <p className="subtitle has-text-centered">
                    <u>Demokonto för SCRIIN skola</u>
                  </p>
                </div>
                <div className="control is-medium mt20">
                  <p className="subtitle has-text-centered">
                    Inloggningsnummer: <b className="text-bold">0799001234</b>
                  </p>
                </div>
                <div className="control is-medium">
                  <p className="subtitle has-text-centered">
                    Kod: <b className="text-bold">1234</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
