/* global google */

import React, { Component, useState, useRef } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  DirectionsRenderer
} from 'react-google-maps';
import { withTranslation } from 'react-i18next';
import { setGoogleExtension } from '../../../helpFunctions/helpFunctions';
import childrenWalkingPink from '../../../resources/assets/icons/children-walking-pink.png';
import childrenWalkingBlue from '../../../resources/assets/icons/children-walking-blue.png';
import markerRed from '../../../resources/assets/icons/marker-red.svg';
import markerBlue from '../../../resources/assets/icons/marker-blue.svg';

class MapDirectionsRenderer extends Component {
  state = {
    directions: null,
    error: null
  };

  componentDidMount() {
    setGoogleExtension(google);
    this.setDirections();
  }

  componentDidUpdate(prevProps) {
    const {
      origin,
      destination,
      totalStepsPerDay,
      totalStepsPerDay2,
      isCommonWay
    } = this.props;
    if (
      origin !== prevProps.origin ||
      destination !== prevProps.destination ||
      totalStepsPerDay !== prevProps.totalStepsPerDay ||
      totalStepsPerDay2 !== prevProps.totalStepsPerDay2 ||
      isCommonWay !== prevProps.isCommonWay
    ) {
      this.setDirections();
    }
  }

  setDirections = () => {
    const {
      travelMode,
      origin,
      destination,
      totalStepsPerDay,
      totalStepsPerDay2,
      setMarkers,
      setTotalDistance,
      isCommonWay,
      map,
      updateLocalStorage,
      t
    } = this.props;

    if (totalStepsPerDay) {
      updateLocalStorage(1);
    } else {
      localStorage.removeItem('class1_organization');
      localStorage.removeItem('class1_group');
      localStorage.removeItem('class1_period');
    }
    if (totalStepsPerDay2) {
      updateLocalStorage(2);
    } else {
      localStorage.removeItem('class2_organization');
      localStorage.removeItem('class2_group');
      localStorage.removeItem('class2_period');
    }
    this.setState({ error: null });

    const bounds = new google.maps.LatLngBounds();
    const directionsService = new google.maps.DirectionsService();
    const polyline = new google.maps.Polyline({
      path: [],
      strokeColor: '#FF0000',
      strokeWeight: 3
    });
    directionsService.route(
      {
        origin,
        destination,
        travelMode
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          var legs = result.routes[0].legs;

          for (var i = 0; i < legs.length; i++) {
            var steps = legs[i].steps;

            for (var j = 0; j < steps.length; j++) {
              var nextSegment = steps[j].path;

              for (var k = 0; k < nextSegment.length; k++) {
                polyline.getPath().push(nextSegment[k]);
              }
            }
          }
          setTotalDistance(
            Math.floor(
              google.maps.geometry.spherical.computeLength(polyline.getPath())
            )
          );
          const reduce = (obj, cb, initialValue) =>
            Object.entries(obj).reduce(
              (prev, [key, value]) => cb(prev, key, value),
              initialValue
            );

          let markers = [],
            class1 = 0,
            class2 = 0;

          if (totalStepsPerDay)
            class1 =
              reduce(
                totalStepsPerDay,
                (prev, key, value) => prev + value.steps,
                0
              ) * 0.762;
          if (totalStepsPerDay2)
            class2 =
              reduce(
                totalStepsPerDay2,
                (prev, key, value) => prev + value.steps,
                0
              ) * 0.762;
          if (isCommonWay) {
            markers.push({
              position: polyline.GetPointAtDistance(class1 + class2),
              distance: Math.round(class1 + class2),
              title: 'Samarbeta',
              iconUrl: childrenWalkingPink
            });
          } else {
            if (class1) {
              markers.push({
                position: polyline.GetPointAtDistance(class1),
                distance: Math.round(class1),
                title: `${t('setGroup.class')} 1`,
                iconUrl: childrenWalkingPink
              });
            }
            if (class2) {
              markers.push({
                position: polyline.GetPointAtDistance(class2),
                distance: Math.round(class2),
                title: `${t('setGroup.class')} 2`,
                iconUrl: childrenWalkingBlue
              });
            }
          }

          setMarkers(markers);
          this.setState({
            directions: result
          });
        } else {
          bounds.extend(origin);
          bounds.extend(destination);
          map.current.fitBounds(bounds);
          setMarkers([]);
          this.setState({ error: 'Directions request returned no results.' });
        }
      }
    );
  };

  render() {
    if (this.state.error) {
      return (
        <h1 className="mt-2" style={{ color: 'red', fontWeight: '600' }}>
          {this.state.error}
        </h1>
      );
    }
    return (
      this.state.directions && (
        <DirectionsRenderer
          options={{ suppressMarkers: true }}
          directions={this.state.directions}
        />
      )
    );
  }
}

const Map = withGoogleMap(props => {
  const [markers, setMarkers] = useState([]);
  const [totalDistance, setTotalDistance] = useState(null);
  const [isOpenWindowId, setIsOpenWindowId] = useState(null);
  const [isOpenDestination, setIsOpenDestination] = useState(false);
  const mapRef = useRef(null);
  const finishIcon =
    markers[0]?.distance >= totalDistance ||
    markers[1]?.distance >= totalDistance
      ? {
          url: childrenWalkingPink,
          scaledSize: new google.maps.Size(60, 49)
        }
      : { url: markerRed };

  return (
    <GoogleMap
      ref={mapRef}
      defaultCenter={props.defaultCenter}
      defaultZoom={props.defaultZoom}
    >
      <Marker
        position={props.origin}
        icon={{
          url: markerBlue
        }}
      />
      {!!markers.length &&
        markers.map((marker, index) => {
          return (
            <Marker
              key={index}
              position={marker.position}
              onClick={() => setIsOpenWindowId(index)}
              icon={{
                url: marker.iconUrl,
                scaledSize: new google.maps.Size(60, 49)
              }}
            >
              {isOpenWindowId === index && (
                <InfoWindow onCloseClick={() => setIsOpenWindowId(null)}>
                  <div>
                    <p>
                      <strong>{marker.title}</strong>
                    </p>
                    <p>{marker.distance}m</p>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
      <Marker
        position={props.destination}
        icon={finishIcon}
        onClick={() => setIsOpenDestination(true)}
      >
        {isOpenDestination && (
          <InfoWindow onCloseClick={() => setIsOpenDestination(false)}>
            <div>
              <p>{totalDistance}m</p>
            </div>
          </InfoWindow>
        )}
      </Marker>
      <MapDirectionsRenderer
        origin={props.origin}
        destination={props.destination}
        totalStepsPerDay={props.totalStepsPerDay}
        totalStepsPerDay2={props.totalStepsPerDay2}
        travelMode={google.maps.TravelMode.DRIVING}
        setMarkers={setMarkers}
        setTotalDistance={setTotalDistance}
        isCommonWay={props.isCommonWay}
        map={mapRef}
        updateLocalStorage={props.updateLocalStorage}
        t={props.t}
      />
    </GoogleMap>
  );
});

export default withTranslation()(Map);
