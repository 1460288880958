import React, { Component, Fragment } from 'react';
import {
  VictoryChart,
  VictoryGroup,
  VictoryBar,
  VictoryTheme,
  VictoryAxis,
  VictoryLabel
} from 'victory';

class DoubleBar extends Component {
  countData = groupName => {
    const { property, report } = this.props;

    return report[groupName].map(item => {
      return {
        x: new Date(new Date(item.date).toDateString()),
        y: isNaN(item[property]) ? 0 : item[property]
      };
    });
  };

  tickFormatY = tick => {
    if (this.props.customLabels) {
      return `${Math.floor(tick)}:00`;
    } else {
      return tick;
    }
  };

  setLabels = ({ datum }) => {
    if (this.props.customLabels) {
      let data = datum.y.toString();
      if (data.length === 1) data = `${data}.0`;
      return `${data.split('.')[0]}:${data.split('.')[1].padStart(2, '0')}`;
    } else {
      return datum.y;
    }
  };

  render() {
    const { title, colorScale, setGroupName } = this.props;

    const data = this.countData(setGroupName);
    const len = data.length;

    return (
      <Fragment>
        <h2>{title}</h2>
        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={{ x: 20 + (100 / len), y: 10 + (100 / len) }}
          width={500}
          height={410}
          scale={{ x: 'time' }}
          padding={{
            left: 90,
            top: 50,
            right: 10,
            bottom: 90
          }}
        >
          <VictoryAxis
            tickLabelComponent={
              <VictoryLabel
                angle={300}
                verticalAnchor={'middle'}
                textAnchor={'end'}
                renderInPortal
              />
            }
            tickValues={data.map(item => item.x)}
            style={{ grid: { stroke: 'none' } }}
            fixLabelOverlap
          />
          <VictoryAxis dependentAxis tickFormat={this.tickFormatY} />
          <VictoryGroup
            offset={10}
            style={{ data: { width: 6 } }}
            colorScale={colorScale}
          >
            <VictoryBar
              labels={data.length <= 14 ? this.setLabels : null}
              data={data}
              barWidth={12}
              cornerRadius={3}
            />
          </VictoryGroup>
        </VictoryChart>
      </Fragment>
    );
  }
}

export default DoubleBar;
