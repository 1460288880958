import * as Actions from './actionTypes';
import { setAccessToken } from 'actions/authActions';

import HTTPService from 'utils/httpService';

export const getOTP = (countryCode, mobile) => dispatch => {
  if (!countryCode.length || !mobile.length) {
    dispatch({
      type: Actions.GET_OTP_REQUEST_FAILURE,
      payload: {
        errorMessage: 'Missing countryCode or mobile'
      }
    });
    return;
  }

  dispatch({ type: Actions.GET_OTP_REQUEST });

  const httpClient = new HTTPService().client;

  httpClient
    .post('registration/code', {
      countryCode,
      mobile
    })
    .then(() => {
      dispatch({ type: Actions.GET_OTP_REQUEST_SUCCESS });
    })
    .catch(() => {
      dispatch({
        type: Actions.GET_OTP_REQUEST_FAILURE,
        payload: {
          errorMessage: 'Error: Could not send SMS code.'
        }
      });
    });
};

export const validateOTP = (code, countryCode, mobile) => dispatch => {
  if (!code.length || !countryCode.length || !mobile.length) {
    dispatch({
      type: Actions.VALIDATE_OTP_FAILURE,
      payload: {
        errorMessage: 'Missing code, countryCode, or mobile'
      }
    });
    return;
  }

  dispatch({ type: Actions.VALIDATE_OTP_REQUEST });

  const httpClient = new HTTPService().client;

  httpClient
    .post('registration/code/verify', {
      key: code,
      countryCode,
      mobile
    })
    .then(response => {
      const { accessToken } = response.data;
      dispatch({ type: Actions.VALIDATE_OTP_SUCCESS });
      dispatch(setAccessToken(accessToken));
    })
    .catch(error => {
      dispatch({
        type: Actions.VALIDATE_OTP_FAILURE,
        payload: {
          errorMessage: 'Error: Failed to validate SMS code.'
        }
      });
    });
};
