import React, { Component } from 'react';

import DatePicker, { registerLocale } from 'react-datepicker';
import enGb from 'date-fns/locale/en-GB';
import './datepicker-default.scss';
registerLocale('en-gb', enGb);

export default class Picker extends Component {
  render() {
    return (
      <DatePicker
        {...this.props}
        locale="en-gb"
        showPopperArrow={false}
        disabledKeyboardNavigation
      />
    );
  }
}
