import React, { Component, Fragment } from 'react';
import Topbar from '../../general/topbar/topbar';

class Main extends Component {
  render() {
    const { title, subtitle, children } = this.props;

    return (
      <Fragment>
        <Topbar title={title} subtitle={subtitle} />

        <div className="pl20 pr20 pt20 pb40 columns is-centered">
          {children}
        </div>
      </Fragment>
    );
  }
}

export default Main;
