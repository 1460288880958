import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  getTrackerReport,
  setGroupName,
  clearReport,
  getGroupsNames
} from '../../../actions/trackerActions';

import SetGroup from './../../presentation/setGroup/setGroup';

class SetGroupContainer extends Component {
  componentDidMount() {
    this.props.getGroupsNames();
  }

  render() {
    const {
      setGroupName,
      getReport,
      setName,
      clearReport,
      groupsNames
    } = this.props;

    return (
      <SetGroup
        setGroupName={setGroupName}
        getReport={getReport}
        setName={setName}
        clearReport={clearReport}
        groupsNames={groupsNames}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    setGroupName: state.tracker.setGroupName,
    groupsNames: state.tracker.groupsNames
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReport: (groupName, fromDate, toDate) => {
      dispatch(getTrackerReport(groupName, fromDate, toDate));
    },
    setName: groupName => {
      dispatch(setGroupName(groupName));
    },
    clearReport: () => {
      dispatch(clearReport());
    },
    getGroupsNames: () => {
      dispatch(getGroupsNames());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetGroupContainer);
