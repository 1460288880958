import React, { Component, Fragment } from 'react';

import styles from '../activityView.module.scss';
import {
  toggleTableArrow,
  minToHours
} from '../../../../helpFunctions/helpFunctions';
import { withTranslation } from 'react-i18next';

class Table extends Component {
  toggleArrow = e =>
    toggleTableArrow(e, {
      id: 'accordionTable',
      colStyle: styles['not-collapsed'],
      notColStyle: styles['is-collapsed']
    });

  render() {
    const { trackersReport, showSedentary, showAverages, t } = this.props;
    if (!trackersReport || !trackersReport.length) return null;

    const avgSteps = Math.round(
      trackersReport.reduce((a, b) => a + b.avrSteps, 0) / trackersReport.length
    );
    const avgActivity = Math.round(
      trackersReport.reduce((a, b) => a + b.avrMedHigh, 0) /
        trackersReport.length
    );
    const avgSedTime = minToHours(
      Math.round(
        trackersReport.reduce((a, b) => a + b.avrSedHoursMin, 0) /
          trackersReport.length
      )
    );

    return (
      <div className={`${styles['container']} mt50 pb50`}>
        <table
          className={`${styles['table']} table is-partwidth is-hoverable accordion`}
          id="accordionTable"
        >
          <thead>
            <tr>
              <th className={`is-dark ${styles['is-dark']} is-narrow`}>
                {t('activity.scriinTracker')}
              </th>
              <th className={`is-dark ${styles['is-dark']} is-narrow`}>
                {t('activity.steps')}
              </th>
              <th className={`is-dark ${styles['is-dark']} is-narrow`}>
                {t('groups.activity.title')} (min)
              </th>
              {showSedentary && (
                <th className={`is-dark ${styles['is-dark']} is-narrow`}>
                  Stillasittande (min)
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {trackersReport
              .filter(item => !isNaN(item.avrSteps))
              .map(
                (
                  { trackerId, avrSteps, avrMedHigh, avrSedHoursMin, dates },
                  index
                ) => {
                  return (
                    <Fragment key={`frag-${index}`}>
                      <tr
                        key={`track-${index}`}
                        id={`heading-${index}`}
                        data-toggle="collapse"
                        data-target={`#collapse-${index}`}
                        aria-expanded="true"
                        aria-controls={`collapse-${index}`}
                        className={`${styles['is-collapsed']}`}
                        onClick={this.toggleArrow}
                      >
                        <td className="is-light">{trackerId.slice(-2)}</td>
                        <td className="is-light">{avrSteps}</td>
                        <td className="is-light">{avrMedHigh}</td>
                        {showSedentary && (
                          <td className="is-light">
                            {minToHours(avrSedHoursMin)}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td
                          colSpan={showSedentary ? '4' : '3'}
                          className={`${styles['is-collapse']}`}
                        >
                          <div
                            id={`collapse-${index}`}
                            className="collapse"
                            aria-labelledby={`heading-${index}`}
                            data-parent="#accordionTable"
                          >
                            <SubTable
                              dates={dates}
                              showSedentary={showSedentary}
                            />
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  );
                }
              )}
            {showAverages && (
              <tr>
                <td className="is-light">
                  <strong>{t('activity.average')}</strong>
                </td>
                <td className="is-light">
                  <strong>{avgSteps}</strong>
                </td>
                <td className="is-light">
                  <strong>{avgActivity}</strong>
                </td>
                {showSedentary && (
                  <td className="is-light">
                    <strong>{avgSedTime}</strong>
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withTranslation()(Table);

const SubTable = ({ dates, showSedentary }) => (
  <table className={`${styles['table']} table is-fullwidth is-hoverable`}>
    <tbody>
      {dates.map(({ date, steps, totalActMin, sed }, index) => {
        return (
          <tr key={`date-${index}`}>
            <td>{date}</td>
            <td>{steps}</td>
            <td>{totalActMin}</td>
            {showSedentary && <td>{minToHours(sed)}</td>}
          </tr>
        );
      })}
    </tbody>
  </table>
);
